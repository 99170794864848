import React, { useEffect, useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import AssignQuestions from "./AssignQuestions";
import axios from "axios";
import "./SectorQuestion.css";
import config from "../../../../config/config.json";
import { Row, Col, Table, Form } from "react-bootstrap";
import QuestionTypeTabSection from "./QuestionTypeTabSection";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { apiCall } from "../../../../_services/apiCall";
import { useRef } from "react";
import { sweetAlert } from "../../../../utils/UniversalFunction";
import { authenticationService } from "../../../../_services/authentication";
import SectorAnswer from "./SectorAnswer";
import Review from "../../../../img/sector/reviewing.png";
import Verified from "../../../../img/sector/accept.png";
import Reject from "../../../../img/sector/decline.png";
import NotAnswered from "../../../../img/sector/notAnswer.png";
import Maximize from "../../../../img/sector/maximize.png";
import Minimize from "../../../../img/sector/minimize.png";
import SectorQuestionFilter from "./Filter/SectorQuestionFilter";
import { USER_TYPE_CODE_MAPPING } from "../../../../_constants/constants";
import { NavLink } from "react-router-dom";
import Loader from "../../../loader/Loader";
import ReAssignQuestions from "./ReassignUser";

const SectorQuestion = (props) => {
  // const questionsIds = props.location?.state?.questionIds
  const [questionList, setQuestionList] = useState();
  const [questionsIds, setQuestionsIds] = useState([]);
  const [quesWidth, setQuesWidth] = useState(6);
  const [ansWidth, setAnsWidth] = useState(6);
  const [hideCol, setHideCol] = useState(false);
  const [sectorQuestionType, setSectorQuestionType] = useState();
  const [uploadItem, setUploadItem] = useState([]);
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [entity, setEntity] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [menuList, setMenuList] = useState([]);

  const [showImport, setShowImport] = useState(false);

  const handleCloseImport = () => setShowImport(false);
  const handleShowImport = () => setShowImport(true);

  const [AllQuestionList, setAllAllQuestionList] = useState({
    quantitative: [],
    tabular_question: [],
    qualitative: [],
    yes_no: [],
    quantitative_trends: [],
  });
  const [auditStatus, setAuditStatus] = useState({});
  const [questionData, setQuestionData] = useState({
    title: "",
    id: "",
    questionType: "",
    frameworkId: "",
    topicId: "",
    kpiId: "",
    title: "",
    question_detail: [],
    audit_status: "",
    add_row: 0,
    formula:null
  });

  const [filterQuestionList, setFilterQuestionList] = useState([]);
  const [data, setData] = useState([]);
  const [companyEsgData, setCompanyEsgData] = useState();
  const [answers, setAnswers] = useState([]);
  const [assignedDetails, setAssignedDetails] = useState([]);
  const [financialYearId, setFinancialYearId] = useState(0);
  const [financialYear, setFinancialYear] = useState([]);
  const authValue = JSON.parse(localStorage.getItem("currentUser"));
  const [initalValue, setInitialValue] = useState({});
  const user_Is_head = authValue?.is_head;
  const [answerChangedData, setAnswerAnswerChangedData] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const ref = useRef();
  const current_role = localStorage.getItem("role");
  const current_user_type_code = USER_TYPE_CODE_MAPPING[current_role];

  let changedData = [];
  useEffect(() => {
    if (entity[0]) {
      getFinancialYear();
    }
  }, [entity[0]]);
  useEffect(() => {
    getFinancialYear();
  }, []);
  useEffect(() => {
    if (filterQuestionList) {
      setQuestionData(filterQuestionList[0]);
      setSelectedRow(filterQuestionList[0]?.id);
    }
  }, [filterQuestionList]);

  useEffect(() => {
    if (financialYearId && entity) fetchStoredData();
  }, [financialYearId, entity]);
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length == 1) {
        setFinancialYearId(data.data[0].id);
        if (financialYearId && entity) {
          fetchStoredData();
        }
      }
    }
  };
  useEffect(() => {
    setInitialValue({
      starting_month: JSON.parse(localStorage.getItem("currentUser"))
        .starting_month,
      frequency: JSON.parse(localStorage.getItem("currentUser")).frequency,
    });
  }, []);

  const answerWidth = () => {
    setQuesWidth(0);
    setAnsWidth(12);
    setHideCol(true);
  };

  const questionWidth = () => {
    setQuesWidth(6);
    setAnsWidth(6);
    setHideCol(false);
  };

  useEffect(() => {
    filterQuestionList?.length &&
      answers?.length &&
      filterQuestionList?.map((item) => {
        // setReviewStatus(item)
        answers.length &&
          answers.filter((answer) => {
            if (answer.questionId === item.id) {
              setAuditStatus((auditStatus) => ({
                ...auditStatus,
                [item.id]:  answer.status,
              }));
            }
          });
      });
  }, [filterQuestionList?.length, answers?.length]);

  const settingEntities = async () => {
    if (current_user_type_code === "company") {
      setEntity([{ name: "company", id: 1 }]);

      setEntityList([
        { name: "company", id: 1 },
        { name: "supplier", id: 3 },
      ]);
    }
    if (current_user_type_code === "supplier") {
      const { isSuccess, data, error } = await getSupplierEntities();
      if (isSuccess) {
        setEntityList(
          (data?.entities).map((entity) => ({
            name: entity.register_company_name,
            id: entity.companyId,
          }))
        );
        // setEntity([
        //   {
        //     name: data?.entities[0].register_company_name,
        //     id: data?.entities[0].companyId,
        //   },
        // ]);
      }
      if (error) {
        swal({
          icon: "error",
          title: "Could not get entity companies!",
          timer: 1000,
        });
      }
    }
  };
  useEffect(() => {
    settingEntities();
    // if (current_user_type_code === "company" && current_role === "COMPANY") {

    // }
  }, []);

  // const setReviewStatus = (item) => {

  // };

  const setQuestionDataFunction = (e, item) => {
    // let filteredAudit= auditStatus.filter((status)=>{if(status.question_id===item.question_id) return status});

    setQuestionData({
      title: item.title,
      id: item.id,
      frameworkId: item?.frameworkId,
      topicId: item?.topicId,
      kpiId: item.kpiId,
      questionType: item.questionType,
      question_detail: item.question_detail,
      qualitativeAnswerBy: item.qualitativeAnswerBy,
      qualitativeStatus: item.qualitativeStatus,
      qualitativeRemark: item.qualitativeRemark,
      add_row: item?.add_row,
      formula:item?.formula
      // audit_status:filteredAudit[0].auditStatus,
    });

    // if(filteredAudit.auditStatus) {
    //   console.log("audit status", item.title, filteredAudit[0].auditStatus)
    // }
  };

  const getSupplierEntities = async () => {
    return await apiCall(
      `${config.API_URL}getSupplierEntities`,
      {},
      {
        supplier_id:
          current_role === "SUPPLIER" ? authValue.id : authValue.parent_id,
      }
    );
  };

  const setEntityValue = (value) => {
    let entityName = entityList?.filter((entity) => {
      if (entity.id === parseInt(value)) {
        return entity;
      }
    });

    setEntity([{ name: entityName[0]?.name, id: parseInt(value) }]);
  };

  const fetchStoredData = async () => {
    // if (user_Is_head == "0") {
    //   getSectorQuestion();
    //   return;
    // }
    if (financialYearId) {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getESGReport`,
        {},
        { type: "SQ", financial_year_id: financialYearId? financialYearId:6},
        "GET"
      );
      if (isSuccess) {
        const responseData = data?.data;
        if (data?.mainCompany && responseData.length === 0) {
          // swal({
          //   title: "Please Select Framework for ESG Reporting.",
          //   text: "",
          //   icon: "error",
          //   button: "OK",
          // });
        } else {
          if (!responseData?.mainCompany) {
            getSectorQuestion([], [], []);
          }

          const storeData = responseData[0]?.frameworkTopicKpi || "{}";
          setCompanyEsgData(storeData);

          if (!storeData.frameworkId || storeData.frameworkId.length === 0) {
            // if (responseData?.mainCompany)
            //   swal({
            //     title: "Please Select Framework for ESG Reporting.",
            //     text: "",
            //     icon: "error",
            //     button: "OK",
            //   });
          } else {
            if (responseData.length === 0 && responseData?.mainCompany) {
              // swal({
              //   title: "Please Select Framework for ESG Reporting.",
              //   text: "",
              //   icon: "error",
              //   button: "OK",
              // });
            } else {
              getSectorQuestion(
                storeData.frameworkId,
                storeData.mandatoryTopicsId
                  .concat(storeData.voluntaryTopicsId)
                  .concat(storeData.customTopicsId),
                storeData.mandatoryKpiId
                  .concat(storeData.voluntaryKpiId)
                  .concat(storeData.customKpiId)
              );
            }
          }
        }
      }
    } else {
      // swal({
      //   title: "Please Select Financial Year",
      //   text: "",
      //   icon: "error",
      //   button: "OK",
      // });
    }
  };
  const getSectorQuestion = (
    frameworkIds,
    topicIds,
    kpiIds,
    selectedDesignationId,
    selectedUserId,
    selectedLocationId,
    fromDate,
    toDate
  ) => {
    setLoading(true);
    setQuestionLoading(true);
    let questionId = props.location?.state?.questionIds;
    if(questionId){
      setFinancialYearId(6);
    }
    const locatStorageIds = localStorage.getItem("questionIds");
    if (locatStorageIds) {
      questionId = [locatStorageIds];
    }
    axios
      .get(
        `${
          config.POSTLOGIN_API_URL_COMPANY
        }getSectorQuestion?type=CUSTOM&financialYearId=${financialYearId?financialYearId:6}&questionnaireType=SQ&frameworkIds=[${frameworkIds}]&topicIds=[${topicIds}]&kpiIds=[${kpiIds}]${
          questionId ? `&questionIds=[${questionId}]` : "&questionIds=undefined"
        }& roleIds=[${selectedDesignationId}]&userIds=[${selectedUserId}]&locationIds=[${selectedLocationId}]&fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: {
            userId: JSON.parse(localStorage.getItem("currentUser")).id,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        // if (current_role === "COMPANY") {
        getSource();
        getProcess();
        localStorage.removeItem("questionIds");
        // }
        // getAnswerCompanyWise();
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          setLoading(false);
          setQuestionLoading(false);
          setData(response.data.data);
          setAllAllQuestionList({
            quantitative: [],
            tabular_question: [],
            qualitative: [],
            yes_no: [],
            quantitative_trends: [],
          });
          setQuestionList(response.data.data);
          setFilterQuestionList(response.data.data);
          setAnswers(response.data.answers);
          setAssignedDetails(response?.data?.assignedDetails);
          const data = response.data.data;
          const newData = {}; // Use a new object to store the updated input fields
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const id = item.id;

            newData[id] = {
              question_id: item?.id,
              questionType: item?.questionType ?? item?.questionType,
              answer: "",
              // answer_id: -1,
              status: false,
            };
            if (item.questionType === "tabular_question") {
              const numberofRow = item.question_detail.filter(
                (d) => d.option_type === "row"
              ).length;
              const numberofColumn = item.question_detail.filter(
                (d) => d.option_type === "column"
              ).length;
              if (item && item.answer && item.answer.length > 0) {
                // Perform any necessary operations with item.answer
              }
              const array2D = Array.from({ length: numberofRow }, () =>
                Array.from({ length: numberofColumn }, () => 0)
              );
              newData[item.id]["answer"] = array2D;
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                tabular_question: [...prevState.tabular_question, item],
              }));
            } else if (item.questionType === "quantitative") {
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                quantitative: [...prevState.quantitative, item],
              }));
            } else if (item.questionType === "qualitative") {
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                qualitative: [...prevState.qualitative, item],
              }));
            } else if (item.questionType === "yes_no") {
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                yes_no: [...prevState.yes_no, item],
              }));
            } else if (item.questionType === "quantitative_trends") {
              newData[item.id]["answer"] = [];
              let i = {
                from_date: "",
                to_date: "",
                meter_id: "",
                process: "",
                reading_value: "",
                note: "",
              };
              newData[item.id]["answer"].push(i);
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                quantitative_trends: [...prevState.quantitative_trends, item],
              }));
            }
          }
          // setInputFields((prevState) => ({ ...prevState, ...newData }));
        } else {
          setData([]);
          setQuestionLoading(false);
          setQuestionList([]);
          setFilterQuestionList([]);
          setAnswers([]);
          setAssignedDetails([]);
          setLoading(false);
          // if (type === "ALL") {
          //   setLoading(false);
          // } else {
          //   setQuestionLoading(false);
          // }
          setFilterQuestionList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const locationArray = data?.data?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      setMeterList(locationArray);
    }
  };
  const getProcess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getProcess`,
      {},
      {type:"ALL"},
      "GET"
    );
    if (isSuccess) {
      setProcessList(data?.data?.reverse());
    }
  };
  const uploadFile = (files) => {
    let tempAnswers = [answers];
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${selectedFile.name}`;
    var formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    var requestOptions = {
      header: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${
        config.AUTH_API_URL_COMPANY
      }uploadFile?current_role=${localStorage.getItem("role")}`,
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result) => {
        let index = uploadItem.index;
        let item = uploadItem.item;
        let url = JSON.parse(result);

        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.questionId == item.id
          );

          if (check !== -1) {
            tempAnswers[check]["proofDocument"] = url?.url;
            tempAnswers[check]["status"] = true;

            setAnswers(tempAnswers);
          } else {
            console.log(
              "questionType: item?.questionType ?? item?.questionType",
              item?.questionType ?? item?.questionType
            );
            let tempObj = {
              questionId: item?.id,
              questionType: item?.questionType ?? item?.questionType,
              answer_id: -1,
              status: true,
              proofDocument: url?.url,
              frameworkId: item?.frameworkId,
              topicId: item?.topicId,
              kpiId: item?.kpiId,
              title: item?.title,
            };
            tempAnswers.push(tempObj);

            setAnswers(tempAnswers);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handlekeyPress = (item, event) => {
    const str = event?.target?.value?.trim();
    if (str && str.startsWith("=")) {
      if (event.key === "Enter" || event.keyCode === 13) {
        console.log("getting value", str);
        //getting spaced string
        const spacedString = getSpacedString(str.substring(1));
        const strArray = spacedString.split(" ");
        //concating string to evaluate
        const evaluatedString = getEvaluatedString(strArray);
        const regex = /[a-zA-Z]/g;
        if (!evaluatedString.match(regex)) {
          const answer = eval(evaluatedString);
          handleAnswers(item, null, item["questionType"], answer);
        } else {
          handleAnswers(item, null, item["questionType"], "");
          alert("Answer is not a proper number value");
        }
      }
    }
  };

  const getEvaluatedString = (strArray, item, event) => {
    let evaluatedStr = "";
    //Itrating through spaced string array
    for (const value of strArray) {
      let str = value && value.toLowerCase();
      console.log("looping str value", value);
      if (["+", "-", "/", "*"].includes(value)) {
        evaluatedStr = evaluatedStr.concat(value);
      } else if (str) {
        console.log("value exist");
        if (str.includes("r") && str.includes("c") && str.includes("q")) {
          const qIndex = str.indexOf("q");
          const cIndex = str.indexOf("c");
          const rIndex = str.indexOf("r");
          if (cIndex > rIndex > qIndex) {
            const qNum = str.substring(1, rIndex);
            const rNum = str.substring(rIndex + 1, cIndex);
            const cNum = str.substring(cIndex + 1);
            const question = questionList && questionList[+qNum - 1];
            if (
              question &&
              question["id"] &&
              question["questionType"] === "tabular_question"
            ) {
              const answerArr = answers.filter((obj) => {
                if (obj["question_id"] == question["id"]) {
                  return obj;
                }
              });
              const tabularAns =
                answerArr && answerArr[0] && answerArr[0]["answer"];
              const answer =
                tabularAns &&
                tabularAns[+rNum - 1] &&
                tabularAns[+rNum - 1][+cNum - 1];

              if (isNaN(answer)) {
                //handleAnswers(item, event, item["questionType"], "");
                alert(
                  `For ${value} the answer is not a number type. i.e. ${answer}`
                );
                break;
              } else {
                evaluatedStr = evaluatedStr.concat(answer);
              }
            } else {
              // handleAnswers(item, event, item["questionType"], "");
              alert(
                `${
                  question["id"]
                    ? `${value} is not a tabular type question.`
                    : `No question exist for ${value}`
                }`
              );
              break;
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `Please enter tabluar question ${value} in proper format. eg Q2R1C2`
            );
            break;
          }
          //getting 2D array value
        } else if (str.includes("q")) {
          const index = str.substring(1);
          const question = questionList && questionList[+index - 1];
          if (
            question &&
            question["id"] &&
            question["questionType"] === "quantitative"
          ) {
            const answerArr = answers.filter((obj) => {
              if (obj["question_id"] == question["id"]) {
                return obj;
              }
            });
            const answer = answerArr && answerArr[0] && answerArr[0]["answer"];

            if (isNaN(answer)) {
              //handleAnswers(item, event, item["questionType"], "");
              alert(
                `For ${value} the answer is not a number type. i.e. ${answer}`
              );
              break;
            } else {
              evaluatedStr = evaluatedStr.concat(answer);
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `${
                question["id"]
                  ? `${value} is not a quantative type question.`
                  : `No question exist for ${value}`
              }`
            );
            break;
          }
        }
      }
    }
    return evaluatedStr;
  };
  const getSpacedString = (strValue) => {
    let str = "";
    for (const char of strValue) {
      if (["+", "-", "/", "*"].includes(char)) {
        str = str.concat(" ", char, " ");
      } else {
        str = str.concat(char);
      }
    }
    return str;
  };
  const handleAnswers = (item, event, questionType, source, value) => {
    const tempAnswers = [...answers];
    // console.log(tempAnswers[0],item,"wefdergrthredawf")
    const index = tempAnswers.findIndex((obj) => obj?.questionId === item?.id);
    console.log(tempAnswers, item, "wefdergrthredawf", index);
    item["questionType"] = questionType;

    switch (questionType) {
      case "tabular_question": {
        handleTabularAnswers(item, event, index, source, value);
        break;
      }
      case "quantitative_trends": {
        handleQunatativeTreds(item, event, index);
        break;
      }
      case "quantitative": {
        handlequantitativeTypes(item, event, index, value);
        break;
      }
      default: {
        handleOtherTypes(item, event, index);
      }
    }
  };

  const handleAssignedDetails = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAssignedDetails`,
      {},
      { financialYearId: financialYearId },
      "GET"
    );
    if (isSuccess) {
      setAssignedDetails(data?.assignedDetails);
    }
  };
  const changeAnswer = (check) => {
    const isValueExists = changedData.includes(check);
    if (!isValueExists) {
      // setAnswerChange((prevState) => {
      //   const updatedChange = Array.isArray(prevState) ? [...prevState] : [];
      //   updatedChange.push(check);
      //   return updatedChange;
      // });
      changedData.push(check);
    }
  };
  const handleDeleteAnswers = (item, event, questionType, value, ind) => {
    switch (questionType) {
      case "tabular_question": {
        // handleTabularAnswers(item, event, index, value);
        break;
      }
      case "quantitative_trends": {
        handleDeleteQunatativeTreds(item, value);
        break;
      }
      case "quantitative": {
        // handlequantitativeTypes(item, event, index, value);
        break;
      }
      default: {
        handleOtherTypes(item, event, ind);
      }
    }
  };
  const handleAddRow = async (item, index) => {
    const lastRow = item.question_detail
      .filter((detail) => detail.option_type === "row")
      .pop();
    axios
      .post(
        `${config.API_URL}addRow`,
        {
          question_id: item.id,
          row_value: lastRow.option,
          current_role: localStorage.getItem("role"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const indexToUpdate = filterQuestionList?.findIndex(
          (question) => question.id === item.id
        );
        const updatedQuestion = { ...filterQuestionList[indexToUpdate] };

        const newRow = {
          id: response?.data?.data?.id, // Assign a unique ID for the new row
          question_id: 24,
          option_type: "row",
          option: response?.data?.data?.value?.toString(),
          rules: null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        updatedQuestion.question_detail.push(newRow);
        const updatedList = [
          ...filterQuestionList?.slice(0, indexToUpdate),
          updatedQuestion,
          ...filterQuestionList?.slice(indexToUpdate + 1),
        ];
        setFilterQuestionList(updatedList);
        console.log("setFilterQuestionList", updatedList);
        let tempAnswers = [...answers];
        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.question_id == item.id
          );
          if (check !== -1) {
            const firstArrayLength = tempAnswers[check]?.answer[0].length;
            const newArray = Array(firstArrayLength).fill("");

            tempAnswers[check]?.answer.push(newArray);

            setAnswers(tempAnswers);
          }
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.response.data.message,
          timer: 1000,
        });
      });
  };
  const handleTabularAnswers = (item, event, index, source, qValue, radio) => {
    console.log("dipak", item, event, index, source, qValue);
    let tempAnswers = answers ? [...answers] : [];
    if (qValue) {
      const d1 = new Date(
        tempAnswers[index]["answer"][item?.indexRow][item?.indexCol - 1]
      );
      const d2 = new Date(event?.target?.value);
      if (d1 > d2) {
        sweetAlert(
          "error",
          `You need to select end date greater than  ${
            tempAnswers[index]["answer"][item?.indexRow][item?.indexCol - 1]
          }`
        );
        return;
      }
    }
    const isContact =
      typeof event === "string" &&
      (event.includes("yes") || event.includes("no"));
    const targetValue = isContact ? event : event?.target?.value || "";

    console.log(event, "asdfghfdsadfg");
    if (index >= 0) {
      //upadte value if index exist
      if (
        tempAnswers[index] &&
        tempAnswers[index]["answer"] &&
        tempAnswers[index]["answer"].length > 0
      ) {
        const value =
          tempAnswers[index]["answer"] &&
          tempAnswers[index]["answer"][item?.indexRow] &&
          tempAnswers[index]["answer"][item?.indexRow][item?.indexCol];
        if (value === 0 || value === "" || value) {
          tempAnswers[index]["answer"][item?.indexRow][item?.indexCol] =
            targetValue;
        } else {
          alert("unexpected question type for answer");
        }
      } else {
        const answer2D = get2DArrayAnswer(item, event);
        tempAnswers[index]["answer"] = answer2D;
        tempAnswers[index]["source_id"] = source;
      }
      const id = tempAnswers[index]["questionId"];
      tempAnswers[index]["questionId"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["source_id"] = source;
      tempAnswers[index]["questionType"] = item?.questionType;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
    } else {
      const answer2D = get2DArrayAnswer(item, event);

      const tempObj = {
        questionId: item?.id,
        questionType: item?.questionType ?? item?.questionType,
        // answer_id: -1,
        status: true,
        answer: answer2D,
        source_id: source,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
    }
  };

  const handleQunatativeTreds = (item, trendsAns, index, formula) => {
    console.log("trends answer", trendsAns);

    // setPreviousData(trendsAns);
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    const changes = changedData?.filter((it) => it !== item?.title);
    changedData = changes;
    setAnswerAnswerChangedData(changes);

    if (index >= 0) {
      //upadte value if index exist
      tempAnswers[index]["answer"] = trendsAns;
      const id = tempAnswers[index]["id"];
      tempAnswers[index]["answer_id"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["questionType"] = item?.questionType;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
      if (!formula) {
        sweetAlert("success", `${item?.title} Successfully saved`);
      }
    } else {
      //update value if index donesn't exist

      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType ?? item?.questionType,
        answer_id: -1,
        status: true,
        answer: trendsAns,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
      sweetAlert("success", `${item?.title} Successfully saved`);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (changedData?.length > 0) {
      sweetAlert("error", `You need to save ${changedData}`);
    } else if (answerChangedData?.length > 0) {
      sweetAlert("error", `You need to save ${answerChangedData}`);
    } else {
      let updatedAnswers = answers.filter((obj) => obj.status === true);

      console.log("updatedAnswers", updatedAnswers);

      const { isSuccess, data, error } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerSectorQuestion`,
        {},
        {
          financialYearId: Number(financialYearId),
          data: updatedAnswers,
        },
        "POST"
      );

      if (isSuccess) {
        setAnswers(data?.answers);
      }

      if (error) {
      }
    }
  };
  const handleDeleteQunatativeTreds = (item, index) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    for (let i = 0; i < tempAnswers.length; i++) {
      if (item.id == tempAnswers[i].question_id) {
        tempAnswers[i].answer.splice(index, 1);
        break;
      }
    }
    setAnswers(tempAnswers);
  };

  const handlequantitativeTypes = (item, event, index, qValue) => {
    console.log(item, "asdfghjhgftdsa");
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    let value = qValue || event?.target?.value;

    if (index >= 0) {
      //upadte value if index exist
      tempAnswers[index]["answer"] = value;
      const id = tempAnswers[index]["id"];
      tempAnswers[index]["answer_id"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
    } else {
      //update value if index donesn't exist
      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType,
        answer_id: -1,
        status: true,
        answer: value,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
    }
  };
  // const handlequantitativeTypes = (item, event, index, qValue) => {
  //   let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
  //   let value = qValue || event?.target?.value;
  //   // if (index >= 0) {
  //   //   //upadte value if index exist
  //   //   tempAnswers[index]["answer"] = value;
  //   //   const id = tempAnswers[index]["id"];
  //   //   tempAnswers[index]["answer_id"] = id || -1;
  //   //   tempAnswers[index]["status"] = true;
  //   //   tempAnswers[index]["questionType"] = item?.questionType;
  //   //   setAnswers(tempAnswers);
  //   // } else {
  //   //update value if index donesn't exist
  //   console.log(
  //     "questionType: item?.questionType ?? item?.questionType",
  //     item?.questionType ?? item?.questionType
  //   );
  //   let tempObj = {
  //     question_id: item?.id,
  //     questionType: item?.questionType ?? item?.questionType,
  //     // answer_id: -1,
  //     status: true,
  //     answer: value,
  //   };
  //   tempAnswers.push(tempObj);
  //   setAnswers(tempAnswers);
  //   // }
  // };
  const handleOtherTypes = (item, event, index) => {
    console.log(item, "asdfghjhgftdsa");
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    if (index >= 0) {
      //upadte value if index exist
      tempAnswers[index]["answer"] = event?.target?.value;
      const id = tempAnswers[index]["id"];
      tempAnswers[index]["answer_id"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
    } else {
      //update value if index donesn't exist
      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType,
        answer_id: -1,
        status: true,
        answer: event?.target?.value,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
    }
  };
  // const handleOtherTypes = (item, event, index) => {
  //   console.log(
  //     "questionType: item?.questionType ?? item?.questionType",
  //     item?.questionType ?? item?.questionType
  //   );
  //   let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
  //   let tempObj = {
  //     question_id: item?.id,
  //     questionType: item?.questionType ?? item?.questionType,
  //     // answer_id: -1,
  //     status: true,
  //     answer: event?.target?.value,
  //   };
  //   tempAnswers.push(tempObj);
  //   setAnswers(tempAnswers);
  //   // }
  // };
  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = JSON.parse(localStorage.getItem("menu")).find(
      (item) => item?.url === "sector_questions"
    );
    setMenuList(settingsMenu?.permissions);
  }, []);
  useEffect(() => {
    if (financialYearId) fetchStoredData();
  }, [entity]);
  useEffect(() => {
    setQuestionsIds(props.location);
    const locatStorageIds = localStorage.getItem("questionIds");
    console.log(props.location?.state?.questionIds,"locationlocation",locatStorageIds)
    if (locatStorageIds || props.location?.state?.questionIds) {
      setFinancialYear( [
       
        {
          "id": 6,
          "financial_year_value": "2023-2024"
        }
      ])
     

      setFinancialYearId(6);
      if (6) fetchStoredData();
    }
  }, [props.location?.state?.questionIds]);
  const get2DArrayAnswer = (item, event, qValue) => {
    const isContact =
      typeof event === "string" &&
      (event.includes("yes") || event.includes("no"));

    const numberofRow = item.question_detail.filter(
      (d) => d.option_type === "row"
    ).length;
    const numberofColumn = item.question_detail.filter(
      (d) => d.option_type === "column"
    ).length;
    let answer2D = Array.from({ length: numberofRow }, () =>
      Array.from({ length: numberofColumn }, () => "")
    );
    answer2D[item?.indexRow][item?.indexCol] =
      qValue || isContact ? event : event?.target?.value || "";
    return answer2D;
  };

  const handleFilterHandler = (
    selectedFrameworkId,
    selectedTopicId,
    selectedKpiId,
    selectedDesignationId,
    selectedUserId,
    selectedLocationId,
    fromDate,
    toDate
  ) => {
    getSectorQuestion(
      selectedFrameworkId,
      selectedTopicId,
      selectedKpiId,
      selectedDesignationId,
      selectedUserId,
      selectedLocationId,
      fromDate,
      toDate
    );
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [reassignShow, setReassignShow] = useState(false);

  const handleReassignClose = () => setReassignShow(false);
  const handleReassignShow = () => setReassignShow(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  return (
    <div>
      <Sidebar dataFromParent={props.location.pathname} />
      <Header />
      <div className="main_wrapper" style={{ minHeight: "auto" }}>
        <section className="inner_wraapper px-3 pt-3">
          {/* <form name="form" onSubmit={fetchStoredData}>
            <div className="select-reporting-criteria">
              <div>
                <button
                  style={{
                    background: "#1f9ed1",
                    border: "none",
                    borderRadius: "5px",
                    height: "50px",
                    color: "#fff",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  onClick={fetchStoredData}
                >
                  Get Question
                </button>
              </div>
            </div>
          </form> */}

          <>
            {financialYear?.length > 0 ? (
              <div className="Introduction">
                <div className="question_section">
                  <div className="hstack justify-content-between pb-2">
                    <div className="Reporting_heading">
                      <h1>Please Select Reporting Criteria</h1>
                    </div>
                    <div className="dropdown hstack pb-2">
                      {/* <p className="m-0">
                                {current_user_type_code === "company"
                                  ? current_role === "COMPANY"
                                    ? "Entity :"
                                    : ""
                                  : "Company :"}
                              </p> */}
                      {/* {authValue?.is_head === 1 &&
                            authValue?.user_type_code === "company" ? (
                              <select
                                className="select_one_all"
                                value={entity}
                                onChange={(e) => setEntity(e.target.value)}
                              >
                                <option value="company">Company</option>
                              </select>
                            ) : ( */}
                      {((current_user_type_code === "company" &&
                        current_role === "COMPANY") ||
                        current_user_type_code === "supplier") && (
                        <select
                          className="select_one_all"
                          onChange={(e) => {
                            setEntityValue(e.target.value);
                          }}
                          defaultValue={
                            current_user_type_code === "company" &&
                            current_role === "COMPANY"
                              ? 1
                              : 3
                          }
                        >
                          {/* {current_role === "COMPANY" ? (
                                    <option value="1">company</option>
                                  ) : ( */}
                          <option
                            value=""
                            disabled
                            selected={
                              current_user_type_code === "supplier"
                                ? true
                                : false
                            }
                          >
                            {current_user_type_code === "company"
                              ? current_role === "COMPANY"
                                ? "Select Entity"
                                : ""
                              : "Select Company"}
                          </option>
                          {/* )} */}
                          {entityList.map((entity) => (
                            <option
                              value={entity.id}
                              ref={ref}
                              selected={
                                current_user_type_code === "company" &&
                                current_role === "COMPANY" &&
                                entity.name === "company"
                              }
                            >
                              {entity.name}
                            </option>
                          ))}
                          {/* <option value="company">Company</option>
                                  <option value="supplier">Supplier</option> */}
                        </select>
                      )}
                    </div>
                    <div className="d-flex dropdown align-items-center">
                      <div className="Reporting_heading">
                        <h1 className="m-0">Financial Year</h1>
                      </div>
                      {financialYear && financialYear?.length === 1 ? (
                        <select
                          className="select_one_all"
                          onChange={async (e) => {
                            if (e.target.value !== "Select Financial Year") {
                              setFinancialYearId(e.target.value);
                              if (financialYearId && entity) {
                                fetchStoredData();
                              }
                            } else {
                              setFinancialYearId("");
                            }
                          }}
                        >
                          {financialYear?.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.financial_year_value}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select
                          className="select_one_all"
                          value={financialYear.find(
                            (obj) => obj.id === financialYearId
                          )}
                          onChange={async (e) => {
                            if (e.target.value !== "Select Financial Year") {
                              setFinancialYearId(e.target.value);
                              if (financialYearId && entity) {
                                fetchStoredData();
                              }
                            } else {
                              setFinancialYearId("");
                            }
                          }}
                        >
                          <option value={0}>Select Financial Year</option>
                          {financialYear?.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.financial_year_value}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    {data.length ? (
                      <div className="d-flex align-tems-center gap-3">
                        <div className="filter_ICOn">
                          <SectorQuestionFilter
                            companyEsgData={companyEsgData}
                            handleFilterHandler={handleFilterHandler}
                          />
                        </div>
                        <>
                          <button
                            className="new_button_style"
                            onClick={handleShow}
                          >
                            Assign Question
                          </button>
                          {show === true && (
                            <AssignQuestions
                              questions={filterQuestionList}
                              financialYearId={financialYearId}
                              fetchquestionApi={getSectorQuestion}
                              entity={entity[0]?.name}
                              show={show}
                              onHide={handleClose}
                              companyEsgData={companyEsgData}
                              handleAssignedDetails={handleAssignedDetails}
                            />
                          )}
                        </>
                        <>
                          <button
                            className="new_button_style"
                            onClick={handleReassignShow}
                          >
                            Reassign Question
                          </button>
                          {reassignShow === true && (
                            <ReAssignQuestions
                              questions={filterQuestionList}
                              financialYearId={financialYearId}
                              fetchquestionApi={getSectorQuestion}
                              entity={entity[0]?.name}
                              reassignShow={reassignShow}
                              onHide={handleReassignClose}
                              companyEsgData={companyEsgData}
                              handleAssignedDetails={handleAssignedDetails}
                            />
                          )}
                        </>
                        {/* )} */}
                        <button
                          className="new_button_style"
                          onClick={() => handleShowImport()}
                        >
                          <i className="fas fa-upload"></i> Import
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    {!loading ? (
                      !data.length ? (
                        financialYearId ? (
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="Introduction framwork_2 d-grid justify-content-center">
                                <div className="col-md-12">
                                  <div className="heading align-items-center">
                                    <h4 className="E_capital font-heading">
                                      No Questionnaire Found
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      ) : (
                        <div>
                          <div className="hstack tab__section_Sector">
                            <QuestionTypeTabSection
                              setSectorQuestionType={setSectorQuestionType}
                              sectorQuestionType={sectorQuestionType}
                              setFilterQuestionList={setFilterQuestionList}
                              AllQuestionList={AllQuestionList}
                              questionList={questionList}
                            />
                            <div className="resize__tabel">
                              {hideCol === false ? (
                                <img
                                  className="mx-2"
                                  src={Maximize}
                                  alt="Maximize"
                                  title="Maximize"
                                  onClick={() => answerWidth()}
                                />
                              ) : (
                                <img
                                  className="mx-2"
                                  src={Minimize}
                                  alt="Minimize"
                                  title="Minimize"
                                  onClick={() => questionWidth()}
                                />
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <Row>
                              <Col md={quesWidth} hidden={hideCol}>
                                <div className="Question__type">
                                  <Table
                                    striped
                                    hover
                                    bordered
                                    className="m-0"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <thead>
                                      <tr className="fixed_tr_section">
                                        <td style={{ width: 55 }}>Sr</td>
                                        <td>Question</td>
                                        <td style={{ width: 55 }}>Status</td>
                                      </tr>
                                    </thead>
                                    {filterQuestionList.length > 0 ? (
                                      <tbody>
                                        {filterQuestionList &&
                                          filterQuestionList?.map(
                                            (item, index) => {
                                              return (
                                                <>
                                                  <tr
                                                    className={
                                                      auditStatus[item.id]
                                                    }
                                                    onClick={(e) => {
                                                      handleRowClick(item.id);
                                                      setQuestionDataFunction(
                                                        e,
                                                        item
                                                      );
                                                    }}
                                                    style={{
                                                      background:
                                                        selectedRow === item.id
                                                          ? "var(--secondaryColor)"
                                                          : "",
                                                    }}
                                                  >
                                                    <td>
                                                      <span
                                                        style={{
                                                          color:
                                                            selectedRow ===
                                                            item.id
                                                              ? "var(--neutralColor)"
                                                              : "",
                                                        }}
                                                      >
                                                        {index + 1}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span
                                                        style={{
                                                          color:
                                                            selectedRow ===
                                                            item.id
                                                              ? "var(--neutralColor)"
                                                              : "",
                                                        }}
                                                      >
                                                        {item.title}
                                                        <NavLink
                                                          to={"#"}
                                                          title="View Guidance"
                                                        >
                                                          <i
                                                            className="fas fa-video ms-3"
                                                            style={{
                                                              color:
                                                                selectedRow ===
                                                                item.id
                                                                  ? "var(--neutralColor)"
                                                                  : "var(--primaryColor)",
                                                              fontSize: 12,
                                                            }}
                                                          ></i>
                                                        </NavLink>
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 42,
                                                      }}
                                                    >
                                                      {auditStatus[item.id] ===
                                                      "IN_VERIFICATION" ? (
                                                        <img
                                                          src={Review}
                                                          alt="In Verification"
                                                          srcSet=""
                                                          style={{ width: "80px" }}
                                                          className="notAnsered_question w-25"
                                                          title="In Verification"
                                                        />
                                                      ) : auditStatus[
                                                          item.id
                                                        ] === "ANSWERED" ? (
                                                        <img
                                                          src={Review}
                                                          alt="In Review"
                                                          srcSet=""
                                                          style={{ width: "45%" }}
                                                          title="In Review"
                                                        />
                                                      ) : auditStatus[
                                                          item.id
                                                        ] === "ACCEPTED" ? (
                                                        <img
                                                          src={Verified}
                                                          alt="Verified"
                                                          style={{ width: "45%" }}
                                                          srcSet=""
                                                          title="Verified"
                                                        />
                                                      ) : auditStatus[
                                                          item.id
                                                        ] === "REJECTED" ? (
                                                        <img
                                                          src={Reject}
                                                          alt="Rejected"
                                                          style={{ width: "45%" }}
                                                          srcSet=""
                                                          title="Rejected"
                                                        />
                                                      ) : (
                                                        <img
                                                          className="notAnsered_question"
                                                          src={NotAnswered}
                                                          alt="Not Answered"
                                                          srcSet=""
                                                          title="Not Answered"
                                                        />
                                                      )}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td>--</td>
                                          <td>NO Questions Found</td>
                                          <td>--</td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </Table>
                                </div>
                              </Col>
                              <Col md={ansWidth}>
                                {questionData && (
                                  <SectorAnswer
                                  formula={questionData?.formula}
                                    questionData={questionData}
                                    financialYearId={financialYearId}
                                    handleAnswers={handleAnswers}
                                    answers={answers}
                                    assignedDetails={assignedDetails}
                                    user_Is_head={user_Is_head}
                                    handleOtherTypes={handleOtherTypes}
                                    changedData={changedData}
                                    meterList={meterList}
                                    processList={processList}
                                    filterQuestionList={filterQuestionList}
                                    setFilterQuestionList={
                                      setFilterQuestionList
                                    }
                                    setAnswers={setAnswers}
                                    setUrl={setUrl}
                                    setShowModal={setShowModal}
                                    setUploadItem={setUploadItem}
                                    uploadItem={uploadItem}
                                    initalValue={initalValue}
                                    questionList={questionList}
                                    hideCol={hideCol}
                                    uploadFile={uploadFile}
                                    handleAssignedDetails={
                                      handleAssignedDetails
                                    }
                                  />
                                )}
                                <div className="Bottom_fooeter">
                                  {hideCol ? (
                                    <button
                                      className="new_button_style"
                                      onClick={submitHandler}
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <button
                                      className="new_button_style"
                                      onClick={submitHandler}
                                    >
                                      Submit
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Modal show={showModal}>
                              <div className="modal-lg">
                                <Modal.Header className="justify-content-end">
                                  <button
                                    variant="outline-dark"
                                    onClick={() => setShowModal(false)}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </Modal.Header>
                                <div className="modal-body">
                                  <img
                                    src={url}
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                    }}
                                  />
                                </div>
                              </div>
                            </Modal>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="hstack justify-content-center mt-3">
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              financialYearId && (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="Introduction framwork_2 d-grid justify-content-center">
                      <div className="col-md-12">
                        <div className="heading align-items-center">
                          <h4 className="E_capital font-heading">
                            No Questionnaire Found
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </>
        </section>
      </div>
      <Modal
        show={showImport}
        onHide={handleCloseImport}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closebutton>
          <Modal.Title>
            <Form.Label>Import Answer</Form.Label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="formbold-file-input">
            <input type="file" name="file" id="file" />
            <label for="file">
              <div>
                <span className="formbold-drop-file"> Drop files here </span>
                <span className="formbold-or"> Or </span>
                <span className="formbold-browse"> Browse </span>
              </div>
            </label>
          </div>
          <div className="text-end mt-3">
            <NavLink to={"#"}>DownLoad Template</NavLink>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="new_button_style__reject"
            onClick={handleCloseImport}
          >
            Cancel
          </button>
          <button className="new_button_style">Import</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SectorQuestion;
