import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { BarChart, ColumnChart, RadioBarChart } from "./Chart";
import { RadioBarChartData, ColumnChartData, BarChartData } from "./chartData";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";

const FrameworkComponent = () => {
  const [filter, setFilter] = useState(false);
  const [radioBarGraphData, setRadioBarGraphData] = useState([]);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [Framework, setFramework] = useState([]);
  const [chartType, setChartType] = useState("");
  const handleFilterClose = () => setFilter(false);

  const myDisclosureProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}frameworkProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      let frameworkOptions = data?.data?.frameworkOptions;
      setFramework(frameworkOptions);
      const GraphData = await RadioBarChartData(data?.data?.categories, data?.data?.series);
      setRadioBarGraphData(GraphData);
      const filter = data?.data?.filter;   
      setSelectedFramework(filter?.frameworkIds);
    }
  };

  const handleApplyFilter = async () => {
    handleFilterClose();
  };

  useEffect(() => {
    myDisclosureProgress();
  }, []);
  return (
    <>
      <Col md={12}>
        <div >

          {/* <div className="esg_score_title d-flex align-items-center justify-content-between">
            <h5>
              <b>Framework Progress</b>
            </h5>
            <button
              onClick={() => {
                setFilter(true);
              }}
              className="new_button_style"
            >
              <i
                className="fas fa-filter"
                title="Framework Progress Filter"
              ></i>
            </button>
          </div> */}
          <div className="p-0">
          <div >
              <input
                type="radio"
                id={`checkbox-GRI`}
                checked={true}
              // onChange={() => handleCheckboxChange(category.title)}
              />
              <label className="fs-5 mx-2" htmlFor={`checkbox-GRI`}>GRI</label>
              <div className="main_text">
                {chartType !== "" ? (
                  chartType
                ) : (
                  <RadioBarChart chartData={radioBarGraphData} />
                )}
              </div>
            </div>
          </div>

        </div>
      </Col>
      <Modal size="md" show={filter} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Form.Label className="align-items-center m-0">
            Framework Progress Filter
          </Form.Label>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formStatusType">
            <Form.Label>Select Framework Name</Form.Label>
            <Form.Select
              aria-label="Select Framework"
              className="w-100"
              value={selectedFramework} 
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSelectedFramework(selectedValue); 
              }}
            >
            
              {Framework.map((framework) => (
                <option key={framework.id} value={framework.title}>
                  {framework.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formStatusType">
            <Form.Label>Select Status Type</Form.Label>
            <Form.Select
              aria-label="Select Status Type"
              onChange={(e) => setSelectedStatus(e.target.value)}
              value={selectedStatus}
            >
              <option value="answered">Answered</option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="notResponded">Not Responded</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formChartType">
            <Form.Label>Select Chart Type</Form.Label>
            <Form.Select
              aria-label="Select Chart Type"
              onChange={(e) => setSelectedChartType(e.target.value)}
              value={selectedChartType}
            >
              <option value="RadioBarChart">Radio Bar Chart</option>
              <option value="BarChart">Bar Chart</option>
              <option value="ColumnChart">Column Chart</option>
            </Form.Select>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <button className="new_button_style" onClick={handleApplyFilter}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FrameworkComponent;
