import React, { useState, useEffect } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import UploadIcon from "./Icons/uploadIcon";
import GaugeChart from "react-gauge-chart";
import NotAssign from "../../../../img/skeletons_types_table.png";

export default function TebularInputCard({
  filterAssignedDetail,
  item,
  value,
  handleOnChange,
  handleKeyDown,
  handleAddRow,
  meterListData,
  readOnlyRes,
  reminderToUser,
  requestDueDate,
}) {
  console.log(item);
  const answer2D = value && value.length > 0 ? value : [];
  const [source, setSource] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [nrOfLevels, setNrOfLevels] = useState(2);

  const chartStyle = {
    width: 60,
    height: 20,
  };
  useEffect(() => {
    if (
      localStorage.getItem("role") === "SUPPLIER" ||
      localStorage.getItem("role") === "SUPPLIER_DATA_ADMIN"
    ) {
      let source_id = JSON.parse(
        localStorage.getItem("currentUser")
      ).source_ids;

      setSource(source_id);
    }
  }, []);
  return (
    <>
      <div className="tableOutput__area">
        <Table striped bordered hover>
          <tbody>
            {filterAssignedDetail ? (
              <>
                <tr
                  style={{
                    background: "var(--primaryColor)",
                  }}
                >
                  <td
                    style={{
                      color: "var(--neutralColor)",
                    }}
                  >
                    Attribute
                  </td>
                  <td
                    style={{
                      color: "var(--neutralColor)",
                    }}
                  >
                    Value
                  </td>
                </tr>
                <tr>
                  <td>Assign By</td>
                  <td>
                    {filterAssignedDetail?.assignedByDetails.map(
                      (user, index) => (
                        <span key={index}>
                          <span
                            data-tooltip={filterAssignedDetail.assignedByDetails
                              .map((user) => user.email)
                              .join(", ")}
                          >
                            {user.first_name} {user.last_name}
                          </span>
                          {index <
                            filterAssignedDetail?.assignedByDetails.length -
                              1 && ", "}
                        </span>
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Assign to</td>
                  <td>
                    {filterAssignedDetail?.assignedToDetails.map(
                      (user, index) => (
                        <span key={index}>
                          <span
                            data-tooltip={filterAssignedDetail?.assignedToDetails
                              .map((user) => user.email)
                              .join(", ")}
                          >
                            {user.first_name} {user.last_name}
                          </span>
                          {index <
                            filterAssignedDetail?.assignedToDetails.length -
                              1 && ", "}
                        </span>
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Assign Date</td>
                  <td>
                    {" "}
                    {new Date(filterAssignedDetail.createdAt).toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>
                    <div className="hstack">
                      <p className="m-0">
                        {new Date(
                          filterAssignedDetail.dueDate
                        ).toLocaleString()}
                      </p>
                      <GaugeChart
                        style={chartStyle}
                        id="gauge-chart2"
                        nrOfLevels={nrOfLevels}
                        colors={["#FF5F6D", "#FFC371"]}
                        // percent={completionPercentage / 100}
                      />
                      {filterAssignedDetail && readOnlyRes && (
                        <i
                          className="fas fa-stopwatch mx-2"
                          title="Reminder"
                          onClick={() => reminderToUser(item.id)}
                        ></i>
                      )}
                      {new Date(filterAssignedDetail.dueDate) < new Date() &&
                        filterAssignedDetail?.assignedBy !== 1 && (
                          <i
                            className="far fa-calendar-plus mx-2"
                            title="Request Due Date"
                            onClick={() => requestDueDate(item.id)}
                          ></i>
                        )}
                      {new Date(filterAssignedDetail.dueDate) < new Date() &&
                        filterAssignedDetail?.dueDateRequested && (
                          <i
                            className="far fa-calendar-plus mx-2"
                            title="Change Due Date"
                            onClick={() => handleShow()}
                          ></i>
                        )}

                      {/* {filterAssignedDetail && readOnlyRes && <><Button variant="info" onClick={() => reminderToUser(item?.id)}>Reminder</Button></>}
                      {((new Date(filterAssignedDetail.dueDate) < new Date()) && (filterAssignedDetail?.assignedBy !== 1)) &&
                        <Button variant="info" onClick={() => requestDueDate(item.id)}>Request Due Date</Button>}
                      {((new Date(filterAssignedDetail.dueDate) < new Date()) && (filterAssignedDetail?.dueDateRequested)) &&
                        <Button variant="info" onClick={() => handleShow2()}>Change Due Date</Button>} */}
                    </div>
                  </td>
                </tr>
              </>
            ) : (
              <img
                src={NotAssign}
                className="w-100"
                alt="Question Not Assign"
                title="Question Not Assign"
              />
            )}
            {/* {
              !filterAssignedDetail && (
                <tr>
                  <td>Assign To </td>
                  <td onClick={handleShow1}>Assign to me</td>
                </tr>
              )
            } */}
            {filterAssignedDetail && (
              <tr>
                <td>Attatchment</td>
                <td>
                  <UploadIcon />
                </td>
              </tr>
            )}
            {filterAssignedDetail && (
              <tr>
                <td>History</td>
                <td>
                  <div style={{ cursor: "pointer" }} onClick={handleShow}>
                    {" "}
                    View
                  </div>
                </td>
              </tr>
            )}
            {filterAssignedDetail && (
              <tr>
                <td>Select Source</td>
                <td>
                  {localStorage.getItem("role") !== "SUPPLIER" &&
                    localStorage.getItem("role") !== "SUPPLIER_DATA_ADMIN" && (
                      <Form.Select
                        aria-label="Default select example p-5"
                        className="form-control"
                        name="meter_id"
                        defaultValue={source}
                        onChange={(event) => setSource(event.target.value)}
                        required
                      >
                        <option value="" hidden>
                          Please Select the Source
                        </option>
                        {meterListData &&
                          meterListData?.map((data, index) => {
                            return (
                              <option key={index} value={data.id}>
                                {data.location}
                              </option>
                            );
                          })}
                      </Form.Select>
                    )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {filterAssignedDetail && (
          <div className="table_width">
            <table className="table">
              <thead>
                <tr className="option_wrapper">
                  <th>
                    {item?.question_detail &&
                      item?.question_detail
                        .filter(function (item) {
                          return item.option_type == "firstRowAndColumnHead";
                        })
                        .map((col, ind) => {
                          return <th key={ind}>{col.option}</th>;
                        })}
                    {/* {qualitativeStatus && (
                <div>
                  {" "}
                  Status:- {""}
                  {qualitativeStatus?.replace(/_/g, " ")}
                </div>
              )}
              {qualitativeRemark && (
                <div>
                  Auditor Remark:- {"  "}
                  {qualitativeRemark}
                </div>
              )} */}
                  </th>
                  {item?.question_detail &&
                    item?.question_detail
                      .filter(function (item) {
                        return item.option_type == "column";
                      })
                      .map((col, ind) => {
                        return <th key={ind}>{col.option}</th>;
                      })}
                </tr>
              </thead>
              <tbody>
                {item?.question_detail &&
                  item?.question_detail
                    .filter(function (item) {
                      return item.option_type == "row";
                    })
                    .map((row, indRow) => {
                      return (
                        <tr
                          key={indRow}
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <th
                            className={
                              row.option.length > 60
                                ? "option_wrapper_width"
                                : row.option.length > 10
                                ? "option_wrapper_width_100"
                                : "option_wrapper"
                            }
                          >
                            {row.option}
                          </th>
                          {item?.question_detail &&
                            item?.question_detail
                              .filter(function (item) {
                                return item.option_type == "column";
                              })
                              .map((col, indCol) => {
                                const startDate =
                                  col.option.includes("Start date");
                                const endDate = col.option.includes("End date");
                                const isEmail =
                                  col.option.includes("E mail") ||
                                  row.option.includes("E mail");
                                const isDate =
                                  col.option.includes("date") ||
                                  row.option.includes("date");
                                const isYesNo =
                                  col.option.includes("Yes/ No") ||
                                  row.option.includes("Yes/ No") ||
                                  col.option.includes("Yes / No") ||
                                  row.option.includes("Yes / No") ||
                                  col.option.includes("Yes/No") ||
                                  row.option.includes("Yes/No");
                                const isYear =
                                  col.option.includes("year") ||
                                  (row.option.includes("year") &&
                                    !row.option.includes("Yes/No"));
                                const isContact =
                                  col.option.includes("Contact") ||
                                  row.option.includes("Contact");
                                const isNumber =
                                  (col.option.includes("FY") &&
                                    !col.option.includes("Remark")) ||
                                  col.option.includes("%") ||
                                  row.option.includes("%") ||
                                  col.option.includes("Number") ||
                                  (row.option.includes("Number") &&
                                    !col.option.includes("Remarks")) ||
                                  col.option.includes("total") ||
                                  col.option.includes("Total") ||
                                  (row.option.includes("Total") &&
                                    !col.option.includes("Unit")) ||
                                  col.option.includes("No.") ||
                                  row.option.includes("No.") ||
                                  col.option.includes("Rate") ||
                                  row.option.includes("Rate") ||
                                  col.option.includes("in Rs") ||
                                  row.option.includes("in Rs") ||
                                  col.option.includes("Percentage");
                                let inputType = "text";
                                if (isYesNo) {
                                  inputType = "radio";
                                } else if (isNumber) {
                                  inputType = "number";
                                } else if (isDate || isYear) {
                                  inputType = "date";
                                } else if (isEmail) {
                                  inputType = "email";
                                } else if (isContact) {
                                  inputType = "tel";
                                }
                                const value =
                                  (answer2D &&
                                    answer2D[indRow] &&
                                    answer2D[indRow][indCol]) ||
                                  "";

                                return (
                                  <td key={indCol}>
                                    {inputType !== "radio" ? (
                                      <input
                                        // readOnly={!filterAssignedDetail}
                                        value={value}
                                        // checked={value}
                                        checked={value === "yes"}
                                        readOnly={readOnlyRes}
                                        type={inputType}
                                        onChange={(event) => {
                                          const isChecked =
                                            event.target.checked;
                                          const tempObj = {
                                            ...item,
                                            indexRow: indRow,
                                            indexCol: indCol,
                                          };
                                          handleOnChange(
                                            tempObj,
                                            inputType === "radio"
                                              ? isChecked
                                                ? "yes"
                                                : "no"
                                              : event,
                                            item?.questionType,
                                            source,
                                            endDate
                                          );
                                        }}
                                        onKeyDown={(event) => {
                                          const isChecked =
                                            event.target.checked;
                                          const tempObj = {
                                            ...item,
                                            indexRow: indRow,
                                            indexCol: indCol,
                                          };
                                          handleKeyDown(
                                            tempObj,
                                            inputType === "radio"
                                              ? isChecked
                                                ? "yes"
                                                : "no"
                                              : event
                                          );
                                        }}
                                      />
                                    ) : (
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value="yes"
                                            checked={value === "yes"}
                                            readOnly={readOnlyRes}
                                            onChange={(event) => {
                                              const isChecked =
                                                event.target.checked;
                                              const newValue = isChecked
                                                ? "yes"
                                                : "no"; // Toggle between "yes" and "no"
                                              const tempObj = {
                                                ...item,
                                                indexRow: indRow,
                                                indexCol: indCol,
                                              };
                                              handleOnChange(
                                                tempObj,
                                                inputType === "radio"
                                                  ? newValue
                                                  : event,
                                                item?.questionType,
                                                source,
                                                endDate
                                              );
                                            }}
                                          />
                                          Yes
                                        </label>

                                        <label>
                                          <input
                                            readOnly={readOnlyRes}
                                            type="radio"
                                            value="no"
                                            checked={value === "no"}
                                            onChange={(event) => {
                                              const isChecked =
                                                event.target.checked;
                                              const newValue = isChecked
                                                ? "no"
                                                : "yes"; // Toggle between "yes" and "no"
                                              const tempObj = {
                                                ...item,
                                                indexRow: indRow,
                                                indexCol: indCol,
                                              };
                                              handleOnChange(
                                                tempObj,
                                                inputType === "radio"
                                                  ? newValue
                                                  : event,
                                                item?.questionType,
                                                source,
                                                endDate
                                              );
                                            }}
                                          />
                                          No
                                        </label>
                                      </div>
                                    )}
                                  </td>
                                );
                              })}
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            {console.log(item?.add_row, "sadfghgwqWEGF")}
            {item?.add_row == 1 ? (
              <button className="new_button_style mb-3" onClick={handleAddRow}>
                Add new row
              </button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>History</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "calc(100vh - 200px)", overflow: "auto" }}>
          <div>
            <hr className="hr-text" data-content="1st History" />
            <Table striped bordered className="m-0">
              <tbody>
                <tr style={{ background: "#ccc" }}>
                  <td style={{ width: 80 }}>Attribute</td>
                  <td>Valve</td>
                </tr>
                <tr>
                  <td>Assign By</td>
                  <td>Company Admin (Satya)</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>12/Dec/2023</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Rejected</td>
                </tr>
                <tr>
                  <td>Answer By</td>
                  <td>satya@sapidblue.com</td>
                </tr>
                <tr>
                  <td>Attatchment</td>
                  <td>Uploaded</td>
                </tr>
                <tr>
                  <td colSpan={2}>Answer</td>
                </tr>
                <tr>
                  <td colSpan={2} className="p-0 m-0">
                    <textarea className="w-100 form-control"></textarea>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div>
            <hr className="hr-text" data-content="2nd History" />
            <Table striped hover bordered className="m-0">
              <tbody>
                <tr style={{ background: "#ccc" }}>
                  <td style={{ width: 80 }}>Attribute</td>
                  <td>Valve</td>
                </tr>
                <tr>
                  <td>Assign By</td>
                  <td>Company Admin (Satya)</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>12/Dec/2023</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Rejected</td>
                </tr>
                <tr>
                  <td>Answer By</td>
                  <td>satya@sapidblue.com</td>
                </tr>
                <tr>
                  <td>Attatchment</td>
                  <td>Uploaded</td>
                </tr>
                <tr>
                  <td colSpan={2}>Answer</td>
                </tr>
                <tr>
                  <td colSpan={2} className="p-0 m-0">
                    <textarea className="w-100 form-control"></textarea>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div>
            <hr className="hr-text" data-content="3rd History" />
            <Table striped hover bordered className="m-0">
              <tbody>
                <tr style={{ background: "#ccc" }}>
                  <td style={{ width: 80 }}>Attribute</td>
                  <td>Valve</td>
                </tr>
                <tr>
                  <td>Assign By</td>
                  <td>Company Admin (Satya)</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>12/Dec/2023</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Accepted</td>
                </tr>
                <tr>
                  <td>Answer By</td>
                  <td>satya@sapidblue.com</td>
                </tr>
                <tr>
                  <td>Attatchment</td>
                  <td>Uploaded</td>
                </tr>
                <tr>
                  <td colSpan={2}>Answer</td>
                </tr>
                <tr>
                  <td colSpan={2} className="p-0 m-0">
                    <textarea className="w-100 form-control"></textarea>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
