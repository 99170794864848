import React, { useEffect, useState } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import AssignQuestions from "../../Company Sub Admin/Component/Sector Questions/AssignQuestions";
import swal from "sweetalert";
import "./AuditDetails.css";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import { NavLink, useLocation } from "react-router-dom";
import QuestionTypeTabSection from "../../Company Sub Admin/Component/Sector Questions/QuestionTypeTabSection";
import AuditListingFilter from "../../Company Sub Admin/Component/Sector Questions/Filter/AuditListingFilter";
import Maximize from "../../../img/sector/maximize.png";
import Minimize from "../../../img/sector/minimize.png";
import AuditCard from "./AuditCard";
import Loader from "../../loader/Loader";
import NoDataFound from "../../../img/no_data_found.png";
import AuditAnswers from "./AuditAnswers";
import { Col, Row, Table, Modal, Button } from "react-bootstrap";
import QuestionTypeTab from "./QuestionTypeTab";
import { findDOMNode } from "react-dom";

const AuditList = ({ listing }) => {
  const [audit_Data, setAudit_Data] = useState([]);
  const [filterAuditData, setFilterAuditData] = useState([]);
  const location = useLocation();
  const [leftWidth, setLeftWidth] = useState(6);
  const [rightWidth, setRightWidth] = useState(6);
  const [hideCol, setHideCol] = useState(false);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState();
  const [financialYearId, setFinancialYearId] = useState(0);
  const [financialYear, setFinancialYear] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [status, setStatus] = useState([]);
  const [update, setUpdate] = useState([]);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState("All");
  const [selectedQuestionIds, setSelectedQuestionIds] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hideAnswerCol, setHideAnswerCol] = useState(false);
  const [auditAnswer, setAuditAnswer] = useState();
  const [assignedList, setAssignedList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [processingList, setProcessingList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const findQuestionIds = (str) => {
    let commaSeparated = str?.split(",") ?? str.trim();

    let questionIds = [];
    let hyphenatedRange = [];

    commaSeparated?.map((data) => {
      data = data.toString().trim();
      if (data.toString().includes("-")) hyphenatedRange.push(data);
      else questionIds.push(parseInt(data));
    });

    hyphenatedRange.map((range) => {
      range = range.split("-");
      let start = range[0];
      let end = range[1];

      for (let i = start; i <= end; i++) {
        questionIds.push(parseInt(i));
      }
    });

    setSelectedQuestionIds(questionIds);
  };
  useEffect(() => {
    if (!filterAuditData?.length) {
      setLeftWidth(12);
      setRightWidth(0);
      setHideAnswerCol(true);
    } else {
      setLeftWidth(6);
      setRightWidth(6);
      setHideAnswerCol(false);
    }
  }, [filterAuditData]);

  const [allQuestionList, setAllAllQuestionList] = useState({
    quantitative: [],
    tabular_question: [],
    qualitative: [],
    yes_no: [],
    quantitative_trends: [],
  });

  const answerWidth = () => {
    setLeftWidth(0);
    setRightWidth(12);
    setHideCol(true);
  };

  const questionWidth = () => {
    setLeftWidth(6);
    setRightWidth(6);
    setHideCol(false);
  };

  const getAuditListing = async (fid) => {
    setIsLoading(true);
    const { isSuccess, data, error } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAuditListing`,
      {},
      {
        topicIds: [],
        kpiIds: [],
        financialYearId: fid,
        question_id: selectedQuestionIds ? `[${selectedQuestionIds}]` : [],
        questionType: selectedQuestionType ? selectedQuestionType : "",
        questionnaire_type: questionnaire ? `[${questionnaire}]` : [],
        update_type: update ? `[${update}]` : [],
        audit_status: status ? `[${status}]` : [],
        supplier_company_id: selectedSupplier ? selectedSupplier : "",
        financial_year: selectedFinancialYear ? selectedFinancialYear : "",
        muduleType: "AUDIT",
      }
    );

    if (isSuccess) {
      setAudit_Data(data?.data);
      setFilterAuditData(data?.data);
      setSelectedRow(data?.data[0]?.questionId);
      setAuditAnswer(data?.data[0]);
      setAssignedList(data?.getAssignedDetails);
      setIsLoading(false);
      getSource();
      getProcess();
      setAllAllQuestionList({
        quantitative: [],
        tabular_question: [],
        qualitative: [],
        yes_no: [],
        quantitative_trends: [],
      });
      const responseData = data.data;
      const newData = {}; // Use a new object to store the updated input fields
      for (let i = 0; i < responseData.length; i++) {
        const item = responseData[i];
        const id = item.questionId;

        newData[id] = {
          question_id: item?.questionId,
          questionType: item?.questionType ?? item?.questionType,
          answer: "",
          // answer_id: -1,
          status: false,
        };
        if (item.questionType === "tabular_question") {      
    
          setAllAllQuestionList((prevState) => ({
            ...prevState,
            tabular_question: [...prevState.tabular_question, item],
          }));
        } else if (item.questionType === "quantitative") {
          setAllAllQuestionList((prevState) => ({
            ...prevState,
            quantitative: [...prevState.quantitative, item],
          }));
        } else if (item.questionType === "qualitative") {
          setAllAllQuestionList((prevState) => ({
            ...prevState,
            qualitative: [...prevState.qualitative, item],
          }));
        } else if (item.questionType === "yes_no") {
          setAllAllQuestionList((prevState) => ({
            ...prevState,
            yes_no: [...prevState.yes_no, item],
          }));
        } else if (item.questionType === "quantitative_trends") {
     
          setAllAllQuestionList((prevState) => ({
            ...prevState,
            quantitative_trends: [...prevState.quantitative_trends, item],
          }));
        }
      }
    }
    if (error) {
      setAudit_Data(data);
      setIsLoading(false);
      swal({
        icon: "error",
        title: data?.message,
        timer: 1000,
      });
    }
  };

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length == 1) {
        setFinancialYearId(data.data[0].id);
        getAuditListing(data.data[0].id);
      }
    }
  };
  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {type:"ALL"},
      "GET"
    );
    if (isSuccess) {
      const locationArray = data?.data?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      setProcessList(locationArray);
    }
  };

  const getProcess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getProcess`,
      {},
      {type:"ALL"},
      "GET"
    );
    if (isSuccess) {
      setProcessingList(data?.data?.reverse());
    }
  };

  const handleSubmit = async (
    questionId,
    answerId,
    remark,
    financialYearId,
    validation,
    questionType
  ) => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: questionId,
        answerId: answerId,
        questionType: questionType,
        remark: remark,
        validation: validation,
        financialYearId: financialYearId,
      },
      "POST"
    );

    if (isSuccess) {
      getAuditListing(financialYearId);
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    getFinancialYear();
    const settingsMenu = JSON.parse(localStorage.getItem("menu"));
    const settingsObject = settingsMenu.find(
      (item) => item.caption === "Audit"
    );
    const settingsSubMenu = settingsObject ? settingsObject.sub_menu : [];
    setMenuList(settingsSubMenu);
  }, []);

  useEffect(() => {
    if (filterAuditData.length) {
      setSelectedRow(filterAuditData[0]?.questionId);
      setAuditAnswer(filterAuditData[0]);
    }
  }, [filterAuditData]);

  return (
    <>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <section className="inner_wraapper px-3 pt-3">
          <div className="color_div_on framwork_2 hol_rell">
            <div className="steps-form">
              <div className="steps-row setup-panel hstack justify-content-between">
                <div className="tabs-top setting_admin my-0">
                  <ul>
                    <li>
                      <NavLink to="/audit-listing" className="activee">
                        Audit Listing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/audit-history"> Audit History </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="d-flex dropdown align-items-center">
                  <p className="m-0"> Financial Year : </p>
                  {financialYear && financialYear?.length === 1 ? (
                    <select
                      className="select_one_all"
                      onChange={async (e) => {
                        if (e.target.value !== "Select Financial Year") {
                          setFinancialYearId(e.target.value);
                          if (financialYearId) {
                            getAuditListing(financialYearId);
                          }
                        } else {
                          setFinancialYearId("");
                        }
                      }}
                    >
                      {financialYear?.map((item, key) => (
                        <option key={key} value={item.id}>
                          {item.financial_year_value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className="select_one_all"
                      value={financialYear.find(
                        (obj) => obj.id === financialYearId
                      )}
                      onChange={async (e) => {
                        if (e.target.value !== "Select Financial Year") {
                          setFinancialYearId(e.target.value);
                          getAuditListing(e.target.value);
                        } else {
                          setFinancialYearId("");
                        }
                      }}
                    >
                      <option value={0}>Select Financial Year</option>
                      {financialYear?.map((item, key) => (
                        <option key={key} value={item.id}>
                          {item.financial_year_value}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                {audit_Data?.length ? (
                  <div className="hstack gap-2 mx-2">
                    <div className="filter_ICOn">
                      <AuditListingFilter
                        setSelectedFinancialYear={setSelectedFinancialYear}
                        setSelectedSupplier={setSelectedSupplier}
                        status={status}
                        setStatus={setStatus}
                        update={update}
                        setUpdate={setUpdate}
                        questionnaire={questionnaire}
                        setQuestionnaire={setQuestionnaire}
                        findQuestionIds={findQuestionIds}
                      />
                    </div>
                    {menuList
                      .find((item) => item.caption === "Audit Module")
                      ?.permissions.some(
                        (permission) =>
                          permission.permissionCode ===
                            "ASSIGN_QUESTION_TO_AUDITOR" && permission.checked
                      ) && (
                      <button
                        className="new_button_style_white"
                        onClick={handleShow}
                      >
                        Assign Question
                      </button>
                    )}

                    {show === true && (
                      <AssignQuestions
                        questions={filterAuditData}
                        financialYearId={6}
                        fetchquestionApi={"fetchquestionApi"}
                        entity="company"
                        show={show}
                        onHide={handleClose}
                        tabType="AUDIT"
                      />
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="Introduction">
            <div className="question_section">
              {audit_Data?.length ? (
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    background: "#1f9ed1",
                    borderBottom: "3px solid #fff",
                  }}
                >
                  <QuestionTypeTab
                    setSelectedQuestionType={setSelectedQuestionType}
                    setAuditAnswer={setAuditAnswer}
                    selectedQuestionType={selectedQuestionType}
                    setFilterAuditData={setFilterAuditData}
                    allQuestionList={allQuestionList}
                    audit_Data={audit_Data}
                  />
                  <div className="resize__tabel">
                    {hideCol === false ? (
                      <img
                        className="mx-2"
                        src={Maximize}
                        alt="Maximize"
                        title="Maximize"
                        onClick={() => answerWidth()}
                      />
                    ) : (
                      <img
                        className="mx-2"
                        src={Minimize}
                        alt="Minimize"
                        title="Minimize"
                        onClick={() => questionWidth()}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {isLoading ? (
                <Loader />
              ) : (
                <Row>
                  <Col
                    className="Question__type"
                    md={leftWidth}
                    hidden={hideCol}
                  >
                    {filterAuditData?.length ? (
                      <Table
                        striped
                        hover
                        bordered
                        className="m-0"
                        style={{ cursor: "pointer" }}
                      >
                        <thead>
                          <tr className="fixed_tr_section">
                            <td style={{ width: 55 }}>Sr No</td>
                            <td>Question</td>
                            <td>Status</td>
                          </tr>
                        </thead>
                        <tbody>
                        
                          {filterAuditData?.map((audit_data, index) => (
                            <AuditCard
                              audit_data={audit_data}
                              answerWidth={answerWidth}
                              questionWidth={questionWidth}
                              hideCol={hideCol}
                              index={index + 1}
                              setAuditAnswer={(data) => {
                                setAuditAnswer(data);
                              }}
                              selectedRow={selectedRow}
                              setUrl={setUrl}
                            />
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <div className="hstack justify-content-center">
                        <img
                          src={NoDataFound}
                          alt="No Data Found"
                          srcset=""
                          style={{ opacity: 0.2 }}
                        />
                      </div>
                    )}
                  </Col>
                  {auditAnswer && (
                    <Col md={rightWidth} hidden={hideAnswerCol}>
                      <AuditAnswers
                        assignedDeatils={assignedList}
                        auditAnswer={auditAnswer}
                        setSelectedRow={setSelectedRow}
                        processList={processList}
                        processingList={processingList}
                        handleValidateSubmit={handleSubmit}
                        module="LISTING"
                      />
                    </Col>
                  )}
                </Row>
              )}
            </div>
          </div>
          <Modal show={showModal}>
            <div className="modal-lg">
              <Modal.Header className="justify-content-end">
                <Button
                  variant="outline-dark"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fa fa-times"></i>
                </Button>
              </Modal.Header>
              <div className="modal-body">
                <img
                  src={url}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </Modal>
        </section>
      </div>
    </>
  );
};

export default AuditList;
