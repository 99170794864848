import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import foot_Logo from "../../img/logol.png";
import head_Logo from "../../img/zais-logo.png";
import "./common.css";
import "./sidebar.css";
import config from "../../config/config.json";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";

const Sidebar = (props) => {
  const contextType = useContext(PermissionMenuContext);
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    const settingsMenu = JSON.parse(localStorage.getItem("menu"));
    setMenuList(settingsMenu);
  }, [contextType.state]);

  const currentURL = window.location.href;
  const splitURL = currentURL.split("/");
  const activeURL = splitURL[4];
  const { Defaults } = props;

  return (
    <div>
      <div className="d-flex" id="wrapper">
        {/* <!-- Sidebar--> */}
        <div
          className="border-end bg-white active-sidebar"
          id="sidebar-wrapper"
        >
          <div className="sidebar-heading color_xl">
            <div className="logo_text">
              <NavLink to="/home">
                <img src={head_Logo} alt="" />{" "}
              </NavLink>
            </div>
          </div>
          <div className="list-group list-group-flush">
            <div className="route-dom">
              <ul className="home_icon_img">
                {menuList?.map((data, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        activeClassName="active"
                        to={`/${data?.url}`}
                        className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                      >
                        {activeURL === data?.url ? (
                          <img
                            src={`${config.BASE_URL}${data?.activeIcon}`}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${config.BASE_URL}${data?.inactiveIcon}`}
                            alt=""
                          />
                        )}
                        <span className="home_boom">{data?.caption}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="slill_bord">
            <img src={foot_Logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
