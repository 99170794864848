import React, { useState } from "react";
import TrandsInputCard from "./TrandsInputCard";
import { Form, Modal, Row, Table, Button } from "react-bootstrap";
import TebularInputCard from "./TebularInputCard";
import TebularInputCardAnswer from "./TabularInputCardAnswer";
import config from "../../../../config/config.json";
import NotAnswered from "../../../../img/sector/notAnswer.png";
import UploadIcon from "./Icons/uploadIcon";
import DownloadIcon from "./Icons/downloadIcon";
import "../../../sidebar/common.css";
import { apiCall } from "../../../../_services/apiCall";
import GaugeChart from "react-gauge-chart";
import DatePicker from "react-datepicker";
import { NavLink } from "react-router-dom";
import ConvertDate from "../../../../utils/ConvertDate";
import NotAssign from ".././../../../img/skeletons_types_table.png";

const SectorAnswer = (props) => {
  const [renderedCount, setRenderedCount] = useState(0);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const userId = currentUser?.id;
  const startDate = new Date("2024-01-04");
  const endDate = new Date("2024-01-20");
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [nrOfLevels, setNrOfLevels] = useState(2);
  const [dueDate, setDueDate] = useState(new Date());

  const {
    formula,
    questionData,
    financialYearId,
    handleAnswers,
    answers,
    assignedDetails,
    user_Is_head,
    handleOtherTypes,
    processList,
    changedData,
    meterList,
    filterQuestionList,
    setAnswers,
    setFilterQuestionList,
    uploadItem,
    initalValue,
    questionList,
    hideCol,
    setUploadItem,
    uploadFile,
    setUrl,
    listing,
    permission,
    handleAuditSubmit,
    handleAssignedDetails,
    auditAnswer,
  } = props;
  let value;
  let attatchment;
  let filterAssignedDetail;
  let qualitativeAnswerBy;
  let qualitativeStatus;
  let qualitativeRemark;
  let tabularStatus;
  let tabularRemark;
  let combinedAnswers;
  let proof_document;
  let quantitativeAnswers = [{}];
  const filterAnswer =
    answers && answers?.filter((obj) => obj.questionId == questionData.id);
  const filterAssignedDetails =
    assignedDetails &&
    assignedDetails?.filter((obj) => obj.questionId == questionData.id);
console.log(formula,"formulaformulaformulaformulaformula");
  if (
    filterAnswer &&
    filterAnswer[0] &&
    filterAnswer[0]["answer"] &&
    filterAnswer[0]["answer"] &&
    questionData.questionType === "quantitative_trends"
  ) {
    quantitativeAnswers = filterAnswer[0]["answer"];
  }
  value = (filterAnswer && filterAnswer[0] && filterAnswer[0]["answer"]) || "";

  attatchment =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["proofDocument"]) || "";
  filterAssignedDetail =
    (filterAssignedDetails && filterAssignedDetails[0]) || "";
  const readOnlyRes =
    filterAssignedDetail &&
    (!filterAssignedDetail?.assignedTo.includes(String(userId)) ||
      new Date(filterAssignedDetail.dueDate) <
        new Date(new Date().setDate(new Date().getDate() - 1)));

  qualitativeAnswerBy =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["answered_by_email"]) ||
    "";
  qualitativeStatus =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["audit_status"]) || "";
  qualitativeRemark =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["audit_remark"]) || "";
  combinedAnswers =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["combinedAnswers"]) ||
    "";
  proof_document =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["proofDocument"]) || "";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getSpacedString = (strValue) => {
    let str = "";
    for (const char of strValue) {
      if (["+", "-", "/", "*"].includes(char)) {
        str = str.concat(" ", char, " ");
      } else {
        str = str.concat(char);
      }
    }
    return str;
  };

  const getEvaluatedString = (strArray, item, event) => {
    let evaluatedStr = "";
    for (const value of strArray) {
      let str = value && value.toLowerCase();
      if (["+", "-", "/", "*"].includes(value)) {
        evaluatedStr = evaluatedStr.concat(value);
      } else if (str) {
        if (str.includes("r") && str.includes("c") && str.includes("q")) {
          const qIndex = str.indexOf("q");
          const cIndex = str.indexOf("c");
          const rIndex = str.indexOf("r");
          if (cIndex > rIndex > qIndex) {
            const qNum = str.substring(1, rIndex);
            const rNum = str.substring(rIndex + 1, cIndex);
            const cNum = str.substring(cIndex + 1);
            const question = questionList && questionList[+qNum - 1];
            if (
              question &&
              question["id"] &&
              question["questionType"] === "tabular_question"
            ) {
              const answerArr = answers?.filter((obj) => {
                if (obj["questionId"] == question["id"]) {
                  return obj;
                }
              });
              const tabularAns =
                answerArr && answerArr[0] && answerArr[0]["answer"];
              const answer =
                tabularAns &&
                tabularAns[+rNum - 1] &&
                tabularAns[+rNum - 1][+cNum - 1];

              if (isNaN(answer)) {
                //handleAnswers(item, event, item["questionType"], "");
                alert(
                  `For ${value} the answer is not a number type. i.e. ${answer}`
                );
                break;
              } else {
                evaluatedStr = evaluatedStr.concat(answer);
              }
            } else {
              // handleAnswers(item, event, item["questionType"], "");
              alert(
                `${
                  question["id"]
                    ? `${value} is not a tabular type question.`
                    : `No question exist for ${value}`
                }`
              );
              break;
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `Please enter tabluar question ${value} in proper format. eg Q2R1C2`
            );
            break;
          }
          //getting 2D array value
        } else if (str.includes("q")) {
          const index = str.substring(1);
          const question = questionList && questionList[+index - 1];
          if (
            question &&
            question["id"] &&
            question["questionType"] === "quantitative"
          ) {
            const answerArr = answers?.filter((obj) => {
              if (obj["questionId"] == question["id"]) {
                return obj;
              }
            });
            const answer = answerArr && answerArr[0] && answerArr[0]["answer"];

            if (isNaN(answer)) {
              //handleAnswers(item, event, item["questionType"], "");
              alert(
                `For ${value} the answer is not a number type. i.e. ${answer}`
              );
              break;
            } else {
              evaluatedStr = evaluatedStr.concat(answer);
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `${
                question["id"]
                  ? `${value} is not a quantative type question.`
                  : `No question exist for ${value}`
              }`
            );
            break;
          }
        }
      }
    }
    return evaluatedStr;
  };

  const handlekeyPress = (item, event) => {
    const str = event?.target?.value?.trim();
    if (str && str.startsWith("=")) {
      if (event.key === "Enter" || event.keyCode === 13) {
        const spacedString = getSpacedString(str.substring(1));
        const strArray = spacedString.split(" ");
        const evaluatedString = getEvaluatedString(strArray);
        const regex = /[a-zA-Z]/g;
        if (!evaluatedString.match(regex)) {
          const answer = eval(evaluatedString);
          handleAnswers(
            item,
            null,
            item.questionType ?? item.questionType,
            answer
          );
        } else {
          handleAnswers(item, null, item.questionType ?? item.questionType, "");
          alert("Answer is not a proper number value");
        }
      }
    }
  };

  const handleAddRow = async (item) => {
    const lastRow = item.question_detail
      ?.filter((detail) => detail.option_type === "row")
      .pop();
    {
      const { isSuccess, data, error } = await apiCall(
        config.API_URL + `addRow`,
        {},
        {
          question_id: item.id,
          row_value: lastRow.option,
          current_role: localStorage.getItem("role"),
        },
        "POST"
      );

      if (isSuccess) {
        const indexToUpdate = filterQuestionList.findIndex(
          (question) => question.id === item.id
        );
        const updatedQuestion = { ...filterQuestionList[indexToUpdate] };

        const newRow = {
          id: data?.data?.id,
          question_id: item.id,
          option_type: "row",
          option: data?.data?.value?.toString(),
          rules: null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        updatedQuestion.question_detail.push(newRow);
        const updatedList = [
          ...filterQuestionList.slice(0, indexToUpdate),
          updatedQuestion,
          ...filterQuestionList.slice(indexToUpdate + 1),
        ];
        setFilterQuestionList(updatedList);
        let tempAnswers = [...answers];
        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.question_id == item.id
          );
          if (check !== -1) {
            const firstArrayLength = tempAnswers[check]?.answer[0].length;
            const newArray = Array(firstArrayLength).fill("");

            tempAnswers[check]?.answer.push(newArray);

            setAnswers(tempAnswers);
          }
        }
      }
    }
  };

  const handleDeleteAnswers = (item, event, questionType, value, ind) => {
    switch (questionType) {
      case "tabular_question": {
        // handleTabularAnswers(item, event, index, value);
        break;
      }
      case "quantitative_trends": {
        handleDeleteQunatativeTreds(item, value);
        break;
      }
      case "quantitative": {
        // handlequantitativeTypes(item, event, index, value);
        break;
      }
      default: {
        handleOtherTypes(item, event, ind);
      }
    }
  };

  const handleDeleteQunatativeTreds = (item, index) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    for (let i = 0; i < tempAnswers.length; i++) {
      if (item.id == tempAnswers[i].question_id) {
        tempAnswers[i].answer.splice(index, 1);
        break;
      }
    }
    setAnswers(tempAnswers);
  };

  const requestDueDate = async (e) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}requestDueDate`,
      {},
      {
        questionId: questionData.id,
      },
      "POST"
    );

    if (isSuccess) {
    }
  };

  const reminderToUser = async (e) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}reminderToUser`,
      {},
      {
        questionId: questionData.id,
      },
      "POST"
    );

    if (isSuccess) {
    }
  };

  const updateDueDate = async (e) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}updateDueDate`,
      {},
      {
        questionId: questionData.id,
        dueDate: ConvertDate(dueDate),
      },
      "POST"
    );

    if (isSuccess) {
      handleClose();
    }
  };
  const handleAssignToMe = async (e) => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}assignedQuestionToUser`,
      {},
      {
        financialYearId: Number(financialYearId),
        assignedToIds: [currentUser.id],
        questionIds: [questionData.id],
        moduleType: "SQ",
        questionnaireType: "CA",
        sourceIds: [],
        dueDate: ConvertDate(dueDate),
      },
      "POST"
    );

    if (isSuccess) {
      handleAssignedDetails();
      handleClose();
    }
  };

  const handleAuditsSubmit = (
    validation,
    questionType,
    answerId,
    financialYearId
  ) => {
    handleAuditSubmit(validation, questionType, answerId, financialYearId);
  };

  const changeAnswer = (check) => {
    const isValueExists = changedData.includes(check);
    if (!isValueExists) {
      // setAnswerChange((prevState) => {
      //   const updatedChange = Array.isArray(prevState) ? [...prevState] : [];
      //   updatedChange.push(check);
      //   return updatedChange;
      // });
      changedData.push(check);
    }
  };

  // useEffect(() => {
  //   const calculateCompletionAndLevels = () => {
  //     const currentDate = new Date();
  //     const totalDays = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));
  //     const daysPassed = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  //     const percentage = (daysPassed / totalDays) * 100;
  //     setCompletionPercentage(percentage);
  //     const remainingLevels = totalDays - daysPassed;
  //     setNrOfLevels(remainingLevels > 0 ? remainingLevels : 0);
  //   };
  //   calculateCompletionAndLevels();
  //   const interval = setInterval(() => {
  //     calculateCompletionAndLevels();
  //     setNrOfLevels(prevLevels => (prevLevels > 0 ? prevLevels - 1 : 0));
  //   }, 24 * 60 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, [startDate, endDate]);
console.log(meterList,"meterListmeterList")
  const chartStyle = {
    width: 60,
    height: 20,
  };
  const handleItemRendered = () => {
    setRenderedCount((prevCount) => prevCount + 1);
  };
  return (
    <>
      <div className="question_right_section">
        {hideCol && (
          <Table bordered striped hover className="m-0">
            <thead>
              <tr className="fixed_tr_section">
                <td style={{ width: 55 }}>Sr No</td>
                <td>Question</td>
                <td style={{ width: 55 }}>Status</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 55 }}>{questionData.index + 1}</td>
                <td>{questionData.title}</td>
                <td style={{ width: 55 }}>
                  <img
                    className="notAnsered_question"
                    src={NotAnswered}
                    alt="Not Answered"
                    srcSet=""
                    title="Not Answered"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        {questionData.questionType == "qualitative" && (
          <div className="tableOutput__area">
            <Table striped bordered className="m-0">
              <tbody>
                {filterAssignedDetail ? (
                  <>
                    <tr className="fixed_tr_section">
                      <td>Attribute</td>
                      <td>Value</td>
                    </tr>

                    <tr>
                      <td>Assign By</td>
                      <td>
                        {filterAssignedDetail?.assignedByDetails &&
                          filterAssignedDetail?.assignedByDetails.map(
                            (user, index) => (
                              <span key={index}>
                                <span
                                  data-tooltip={
                                    filterAssignedDetail?.assignedByDetails &&
                                    filterAssignedDetail?.assignedByDetails
                                      .map((user) => user.email)
                                      .join(", ")
                                  }
                                >
                                  {user.first_name} {user.last_name}
                                </span>

                                {index <
                                  filterAssignedDetail?.assignedByDetails
                                    .length -
                                    1 && ", "}
                              </span>
                            )
                          )}
                      </td>
                    </tr>

                    <tr>
                      <td>Assign to</td>
                      <td>
                        {filterAssignedDetail?.assignedToDetails &&
                          filterAssignedDetail?.assignedToDetails.map(
                            (user, index) => (
                              <span key={index}>
                                <span
                                  data-tooltip={filterAssignedDetail?.assignedToDetails
                                    .map((user) => user.email)
                                    .join(", ")}
                                >
                                  {user.first_name} {user.last_name}
                                </span>
                                {index <
                                  filterAssignedDetail?.assignedToDetails
                                    .length -
                                    1 && ", "}
                              </span>
                            )
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Assign Date</td>
                      <td>
                        {new Date(
                          filterAssignedDetail.createdAt
                        ).toLocaleString()}
                      </td>
                    </tr>

                    <tr>
                      <td>Due Date</td>
                      <td>
                        <div className="hstack">
                          <p className="m-0">
                            {new Date(
                              filterAssignedDetail.dueDate
                            ).toLocaleString()}
                          </p>
                          <GaugeChart
                            style={chartStyle}
                            id="gauge-chart2"
                            nrOfLevels={nrOfLevels}
                            colors={["#3f88a5", "#1f9ed1"]}
                            // percent={completionPercentage / 100}
                          />
                          {filterAssignedDetail && readOnlyRes && (
                            <i
                              className="fas fa-stopwatch mx-2"
                              title="Reminder"
                              onClick={() => reminderToUser(questionData.id)}
                            ></i>
                          )}
                          {new Date(filterAssignedDetail.dueDate) <
                            new Date() &&
                            filterAssignedDetail?.assignedBy !== 1 && (
                              <i
                                className="far fa-calendar-plus mx-2"
                                title="Request Due Date"
                                onClick={() => requestDueDate(questionData.id)}
                              ></i>
                            )}
                          {new Date(filterAssignedDetail.dueDate) <
                            new Date() &&
                            filterAssignedDetail?.dueDateRequested && (
                              <i
                                className="far fa-calendar-plus mx-2"
                                title="Change Due Date"
                                onClick={() => handleShow()}
                              ></i>
                            )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>History</td>
                      <td>View</td>
                    </tr>
                  </>
                ) : (
                  <img
                    src={NotAssign}
                    className="w-100"
                    alt="Question Not Assign"
                    title="Question Not Assign"
                  />
                )}
                {qualitativeStatus?.length > 0 && (
                  <tr>
                    <td>Status</td>
                    <td>{qualitativeStatus?.replace(/_/g, " ")}</td>
                  </tr>
                )}
                {qualitativeAnswerBy?.length > 0 && (
                  <tr>
                    <td style={{ width: "150px" }}>Answer By</td>
                    <td>{qualitativeAnswerBy}</td>
                  </tr>
                )}
                {qualitativeRemark?.length > 0 && (
                  <tr>
                    <td> Auditor Remark</td>
                    <td>{qualitativeRemark}</td>
                  </tr>
                )}

                {filterAssignedDetail && (
                  <tr>
                    <td>Attatchment</td>
                    <td>
                      {attatchment ? (
                        <DownloadIcon attatchment={attatchment} />
                      ) : (
                        <UploadIcon
                          setUploadItem={setUploadItem}
                          uploadFile={uploadFile}
                          questionData={questionData}
                        />
                      )}
                    </td>
                  </tr>
                )}
                {filterAssignedDetail && (
                  <>
                    <tr>
                      <td colSpan={2}>Answer</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="p-0">
                        <textarea
                          className="form-control"
                          name="answers"
                          required
                          value={value}
                          readOnly={readOnlyRes}
                          // readOnly={filterAssignedDetail && (!(filterAssignedDetail?.assignedTo.includes(String(userId))) || (new Date(filterAssignedDetail.dueDate) < new Date()))}
                          placeholder="Leave a Answer here"
                          onChange={(e) => {
                            handleAnswers(
                              questionData,
                              e,
                              questionData?.questionType
                            );
                          }}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        )}
        <div
          onLoad={handleItemRendered}
          className={`${
            questionData.assigned_to && user_Is_head !== 0
              ? "disable__section__wrapper row align-items-center"
              : "row align-items-center"
          }`}
          style={{ position: "relative" }}
        >
          {questionData.questionType === "quantitative_trends" && (
            <div className="col-md-12">
              <div>
                <TrandsInputCard
                  item={questionData}
                  answer={answers?.filter(
                    (obj) => obj?.questionId === questionData?.id
                  )}
                  handleSubmit={(questionData, answer) =>
                    handleAnswers(
                      questionData,
                      answer,
                      questionData?.questionType ?? questionData?.questionType
                    )
                  }
                  handleDeleteSubmit={(
                    questionData,
                    answer,
                    tempArray,
                    index
                  ) =>
                    handleDeleteAnswers(
                      questionData,
                      answer,
                      questionData?.questionType,
                      tempArray,
                      index
                    )
                  }
                  handleAuditssSubmit={handleAuditsSubmit}
                  changeAns={() => changeAnswer(questionData?.title)}
                  formula={
                    questionData?.formula == null
                      ? listing === "audit"
                        ? true
                        : false
                      : true
                  }
                  listing={listing}
                  initalValue={initalValue}
                  meterListData={meterList}
                  processListData={processList}
                  permission={permission}
                  filterAssignedDetail={filterAssignedDetail}
                  handleShow1={handleShow}
                  readOnlyRes={readOnlyRes}
                  reminderToUser={reminderToUser}
                  requestDueDate={requestDueDate}
                  handleShow2={handleShow}
                  auditAnswer={auditAnswer}
                  // setUrl={ setUrl}
                />
              </div>
            </div>
          )}
          <div className="col-md-12">
            {questionData.questionType == "yes_no" && (
              <div className="tableOutput__area">
                <Table striped bordered className="m-0">
                  <tbody>
                    {filterAssignedDetail ? (
                      <>
                        <tr className="fixed_tr_section">
                          <td>Attribute</td>
                          <td>Value</td>
                        </tr>

                        <tr>
                          <td>Assign By</td>
                          <td>
                            {filterAssignedDetail?.assignedByDetails.map(
                              (user, index) => (
                                <span key={index}>
                                  <span
                                    data-tooltip={filterAssignedDetail.assignedByDetails
                                      .map((user) => user.email)
                                      .join(", ")}
                                  >
                                    {user.first_name} {user.last_name}
                                  </span>
                                  {index <
                                    filterAssignedDetail?.assignedByDetails
                                      .length -
                                      1 && ", "}
                                </span>
                              )
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Assign to</td>
                          <td>
                            {filterAssignedDetail?.assignedToDetails.map(
                              (user, index) => (
                                <span key={index}>
                                  <span
                                    data-tooltip={filterAssignedDetail?.assignedToDetails
                                      .map((user) => user.email)
                                      .join(", ")}
                                  >
                                    {user.first_name} {user.last_name}
                                  </span>
                                  {index <
                                    filterAssignedDetail?.assignedToDetails
                                      .length -
                                      1 && ", "}
                                </span>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Assign Date</td>
                          <td>
                            {" "}
                            {new Date(
                              filterAssignedDetail.createdAt
                            ).toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td>Due Date</td>
                          <td>
                            <div className="hstack">
                              <p className="m-0">
                                {new Date(
                                  filterAssignedDetail.dueDate
                                ).toLocaleString()}
                              </p>
                              <GaugeChart
                                style={chartStyle}
                                id="gauge-chart2"
                                nrOfLevels={nrOfLevels}
                                colors={["#3f88a5", "#1f9ed1"]}
                                // percent={completionPercentage / 100}
                              />

                              {filterAssignedDetail && readOnlyRes && (
                                <i
                                  className="fas fa-stopwatch mx-2"
                                  title="Reminder"
                                  onClick={() =>
                                    reminderToUser(questionData.id)
                                  }
                                ></i>
                              )}
                              {new Date(filterAssignedDetail.dueDate) <
                                new Date() &&
                                filterAssignedDetail?.assignedBy !== 1 && (
                                  <i
                                    className="far fa-calendar-plus mx-2"
                                    title="Request Due Date"
                                    onClick={() =>
                                      requestDueDate(questionData.id)
                                    }
                                  ></i>
                                )}
                              {new Date(filterAssignedDetail.dueDate) <
                                new Date() &&
                                filterAssignedDetail?.dueDateRequested && (
                                  <i
                                    className="far fa-calendar-plus mx-2"
                                    title="Change Due Date"
                                    onClick={() => handleShow()}
                                  ></i>
                                )}

                              {/* {filterAssignedDetail && readOnlyRes && <><Button variant="info" onClick={() => reminderToUser(questionData.id)}>Reminder</Button></>}
                              {((new Date(filterAssignedDetail.dueDate) < new Date()) && (filterAssignedDetail?.assignedBy !== 1)) &&
                                <Button variant="info" onClick={() => requestDueDate(questionData.id)}>Request Due Date</Button>}
                              {((new Date(filterAssignedDetail.dueDate) < new Date()) && (filterAssignedDetail?.dueDateRequested)) &&
                                <Button variant="info" onClick={() => handleShow()}>Change Due Date</Button>} */}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>History</td>
                          <td>View</td>
                        </tr>
                      </>
                    ) : (
                      <img
                        src={NotAssign}
                        className="w-100"
                        alt="Question Not Assign"
                        title="Question Not Assign"
                      />
                    )}

                    {qualitativeStatus?.length > 0 && (
                      <tr>
                        <td>Status</td>
                        <td>{qualitativeStatus?.replace(/_/g, " ")}</td>
                      </tr>
                    )}
                    {qualitativeAnswerBy?.length > 0 && (
                      <tr>
                        <td style={{ width: "150px" }}>Answer By</td>
                        <td>{qualitativeAnswerBy}</td>
                      </tr>
                    )}
                    {qualitativeRemark?.length > 0 && (
                      <tr>
                        <td>Auditor Remark</td>
                        <td>{qualitativeRemark}</td>
                      </tr>
                    )}
                    {filterAssignedDetail && (
                      <>
                        <tr>
                          <td>Attatchment</td>
                          <td>
                            {attatchment && attatchment.startsWith("http") ? (
                              <DownloadIcon />
                            ) : (
                              <UploadIcon
                                setUploadItem={setUploadItem}
                                uploadFile={uploadFile}
                                questionData={questionData}
                              />
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    {filterAssignedDetail && (
                      <tr>
                        <td>Answer</td>
                        <td>
                          <Form>
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <input
                                  inline="true"
                                  label="Yes"
                                  name="group1"
                                  type={"radio"}
                                  disabled={readOnlyRes}
                                  value={"yes"}
                                  checked={value === "yes"}
                                  onChange={(value) => {
                                    handleAnswers(
                                      questionData,
                                      value,
                                      questionData?.questionType
                                    );
                                  }}
                                />
                                <span className="mx-2">Yes</span>
                              </div>
                              <div>
                                <input
                                  inline="true"
                                  label="No"
                                  name="group1"
                                  type={"radio"}
                                  disabled={readOnlyRes}
                                  value={"no"}
                                  checked={value === "no"}
                                  onChange={(value) => {
                                    handleAnswers(
                                      questionData,
                                      value,
                                      questionData?.questionType
                                    );
                                  }}
                                />
                                <span className="mx-2">No</span>
                              </div>
                            </div>
                          </Form>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}

            {questionData.questionType == "tabular_question" &&
              listing !== "audit" &&
              listing !== "HISTROY" && (
                <TebularInputCard
                  qualitativeStatus={qualitativeStatus}
                  qualitativeRemark={qualitativeRemark}
                  item={questionData}
                  filterAssignedDetail={filterAssignedDetail}
                  value={value}
                  handleAddRow={() => handleAddRow(questionData)}
                  handleOnChange={(
                    questionData,
                    event,
                    questionType,
                    source,
                    endDate
                  ) =>
                    handleAnswers(
                      questionData,
                      event,
                      "tabular_question",
                      source,
                      endDate
                    )
                  }
                  handleKeyDown={(questionData, event) =>
                    handlekeyPress(questionData, event)
                  }
                  meterListData={meterList}
                  readOnlyRes={readOnlyRes}
                  handleShow1={handleShow}
                  reminderToUser={reminderToUser}
                  requestDueDate={requestDueDate}
                  handleShow2={handleShow}
                />
              )}
            {questionData.questionType == "tabular_question" && (
              <>
                <TebularInputCardAnswer
                  filterAssignedDetail={filterAssignedDetail}
                  item={questionData}
                  combinedAnswers={combinedAnswers}
                  userIsHead={user_Is_head}
                  userIsHistory={0}
                  meterListData={meterList}
                  auditAnswer={auditAnswer}
                  handleValidateSubmit={handleAuditsSubmit}
                  listing={listing}
                />
              </>
            )}
            {questionData.questionType == "quantitative" && (
              <div className="tableOutput__area">
                <Table striped bordered className="m-0">
                  <tbody>
                    {filterAssignedDetail ? (
                      <>
                        <tr className="fixed_tr_section">
                          <td>Attribute</td>
                          <td>Value</td>
                        </tr>

                        <tr>
                          <td>Assign By</td>
                          <td>
                            {filterAssignedDetail?.assignedByDetails.map(
                              (user, index) => (
                                <span key={index}>
                                  <span
                                    data-tooltip={filterAssignedDetail.assignedByDetails
                                      .map((user) => user.email)
                                      .join(", ")}
                                  >
                                    {user.first_name} {user.last_name}
                                  </span>
                                  {index <
                                    filterAssignedDetail?.assignedByDetails
                                      .length -
                                      1 && ", "}
                                </span>
                              )
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Assign to</td>
                          <td>
                            {filterAssignedDetail?.assignedToDetails.map(
                              (user, index) => (
                                <span key={index}>
                                  <span
                                    data-tooltip={filterAssignedDetail?.assignedToDetails
                                      .map((user) => user.email)
                                      .join(", ")}
                                  >
                                    {user.first_name} {user.last_name}
                                  </span>
                                  {index <
                                    filterAssignedDetail?.assignedToDetails
                                      .length -
                                      1 && ", "}
                                </span>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Assign Date</td>
                          <td>
                            {" "}
                            {new Date(
                              filterAssignedDetail.createdAt
                            ).toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td>Due Date</td>
                          <td>
                            <div className="hstack">
                              <p className="m-0">
                                {new Date(
                                  filterAssignedDetail.dueDate
                                ).toLocaleString()}
                              </p>
                              <GaugeChart
                                style={chartStyle}
                                id="gauge-chart2"
                                nrOfLevels={nrOfLevels}
                                colors={["#3f88a5", "#1f9ed1"]}
                                // percent={completionPercentage / 100}
                              />
                              {filterAssignedDetail && readOnlyRes && (
                                <i
                                  className="fas fa-stopwatch mx-2"
                                  title="Reminder"
                                  onClick={() =>
                                    reminderToUser(questionData.id)
                                  }
                                ></i>
                              )}
                              {new Date(filterAssignedDetail.dueDate) <
                                new Date() &&
                                filterAssignedDetail?.assignedBy !== 1 && (
                                  <i
                                    className="far fa-calendar-plus mx-2"
                                    title="Request Due Date"
                                    onClick={() =>
                                      requestDueDate(questionData.id)
                                    }
                                  ></i>
                                )}
                              {new Date(filterAssignedDetail.dueDate) <
                                new Date() &&
                                filterAssignedDetail?.dueDateRequested && (
                                  <i
                                    className="far fa-calendar-plus mx-2"
                                    title="Change Due Date"
                                    onClick={() => handleShow()}
                                  ></i>
                                )}

                              {/* {filterAssignedDetail && readOnlyRes && <><Button variant="info" onClick={() => reminderToUser(questionData.id)}>Reminder</Button></>}
                              {((new Date(filterAssignedDetail.dueDate) < new Date()) && (filterAssignedDetail?.assignedBy !== 1)) &&
                                <Button variant="info" onClick={() => requestDueDate(questionData.id)}>Request Due Date</Button>}
                              {((new Date(filterAssignedDetail.dueDate) < new Date()) && (filterAssignedDetail?.dueDateRequested)) &&
                                <Button variant="info" onClick={() => handleShow()}>Change Due Date</Button>} */}
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <img
                        src={NotAssign}
                        className="w-100"
                        alt="Question Not Assign"
                        title="Question Not Assign"
                      />
                    )}
                    {filterAssignedDetail?.audit_status && (
                      <>
                        <tr>
                          <td>Audited Date</td>
                          <td>12/Dec/2023 | 23:59</td>
                        </tr>
                        <tr>
                          <td>Audited By</td>
                          <td>Dipak</td>
                        </tr>
                        <tr>
                          <td>Audited Status</td>
                          <td>Accepted</td>
                        </tr>
                      </>
                    )}
                    {/* {
                      !filterAssignedDetail && (
                        <tr>
                          <td>Assign To </td>
                          <td onClick={handleShow}>Assign to me</td>
                        </tr>
                      )
                    } */}
                    {qualitativeStatus?.length > 0 && (
                      <tr>
                        <td>Status</td>
                        <td>{qualitativeStatus?.replace(/_/g, " ")}</td>
                      </tr>
                    )}
                    {qualitativeAnswerBy?.length > 0 && (
                      <tr>
                        <td style={{ width: "150px" }}>Answer By</td>
                        <td>{qualitativeAnswerBy}</td>
                      </tr>
                    )}
                    {qualitativeRemark?.length > 0 && (
                      <tr>
                        <td>Auditor Remark</td>
                        <td>{qualitativeRemark}</td>
                      </tr>
                    )}
                    {filterAssignedDetail && (
                      <tr>
                        <td>Attatchment</td>
                        <td>
                          {attatchment && attatchment.startsWith("http") ? (
                            <DownloadIcon attatchment={attatchment} />
                          ) : (
                            <UploadIcon
                              setUploadItem={setUploadItem}
                              uploadFile={uploadFile}
                              questionData={questionData}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                    {filterAssignedDetail && (
                      <>
                        {" "}
                        <tr>
                          <td>History</td>
                          <td>View</td>
                        </tr>
                        <tr>
                          <td>Answer</td>

                          <td>
                            <input
                              className="p-2"
                              type="number"
                              value={value}
                              readOnly={readOnlyRes}
                              onKeyDown={(e) => {
                                handlekeyPress(questionData, e);
                              }}
                              onChange={(value) => {
                                handleAnswers(
                                  questionData,
                                  value,
                                  questionData?.questionType
                                );
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Due Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <label>Select Due Date</label>
            <DatePicker
              selected={dueDate}
              onChange={(e) => {
                setDueDate(e);
              }}
              className="form-control date-picker-input"
              required
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <NavLink to={"/"}>Clear</NavLink>
          <Button className="new_button_style w-auto" onClick={updateDueDate}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SectorAnswer;
