import React, { useEffect, useState } from "react";
import OrgChart from "react-orgchart";
import "react-orgchart/index.css";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import swal from "sweetalert";

const FlowCharts = (props) => {
  const { userPermissionList } = props;
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const userId = currentUser?.id;
  const [nodeId, setNodeId] = useState(2);
  const [orgChartData, setOrgChartData] = useState({
    id: 1,
    name: "",
    actor: "",
    userId: 1,
    level: 1,
    audit: "",
    children: [],
  });

  const updateIdAndLevel = (node, parentLevel) => {
    node.level = parentLevel + 1;
    node.id = nodeId;
    setNodeId(nodeId + 1);

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        updateIdAndLevel(node.children[i], node.level);
      }
    }
  };
  const findMaxId = async (jsonData) => {
    const traverseAndFindMaxId = (node) => {
      let maxId = node.id;
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          const childMaxId = traverseAndFindMaxId(child);
          maxId = Math.max(maxId, childMaxId);
        });
      }

      return maxId;
    };
    const maxId = traverseAndFindMaxId(jsonData);

    return maxId;
  };
  const getOrgChart = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getOrgChart`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setOrgChartData(data?.data?.orgChart);
      const maxId = await findMaxId(data?.data?.orgChart);
      setNodeId(maxId + 1);
    }
  };
  useEffect(() => {
    getOrgChart();
  }, []);
  const updateUserList = (nodeId, actor) => {
    const { parentNode, foundNode } = findParentById(orgChartData, nodeId);

    setOrgChartData((prevData) => {
      if (parentNode && foundNode) {
        if (foundNode.userId === null) {
          foundNode.roleId = actor.id;
          foundNode.name = actor.roleName;

          const newDataVariable = { ...prevData };

          saveChart(newDataVariable, null, null, null);
          return newDataVariable;
        } else {
          foundNode.userId = actor.id;
          foundNode.name = actor.name;
          foundNode.actor = actor.name;
          const newDataVariable = { ...prevData };

          saveChart(newDataVariable, null, null, null);
          return newDataVariable;
        }
      }
      const newDataVariable = { ...prevData };

      saveChart(newDataVariable, null, null, null);

      return prevData;
    });
  };
  const handleAddChild = (parentId, level, role, actor, audit) => {
    setOrgChartData((prevData) => {
      const updatedData = { ...prevData };
      const parentNode = findNodeById(updatedData, parentId);

      const newChild = {
        id: nodeId,
        name: role?.roleName || actor?.name || "Default Name",
        actor: actor?.name || "Default Actor",
        level: level,
        roleId: role?.id || null,
        userId: actor?.id || null,
        parentUserId: parentNode.userId
          ? parentNode.userId
          : parentNode.parentUserId,
        audit: "",
        children: [],
      };

      if (!parentNode.children) {
        parentNode.children = [];
      }

      parentNode.children.push(newChild);

      updateIdAndLevel(newChild, level, parentId + 1);

      const parentUserId = parentNode.userId
        ? parentNode.userId
        : parentNode.parentUserId;
      const newDataVariable = { ...updatedData };

      saveChart(newDataVariable, actor?.id, parentUserId, audit);

      return updatedData;
    });
  };

  const getAssignedQuestion = async (userId) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAssignedQuestion`,
      {},
      { userId: userId },
      "GET"
    );
    if (isSuccess) {
      return data.data;
    }
  };

  const handleRemoveNode = async (nodeId) => {
    const { parentNode, foundNode } = findParentById(orgChartData, nodeId);
    const assignedDetails = await getAssignedQuestion(foundNode?.userId);

    if (assignedDetails && assignedDetails.length > 0) {
      swal({
        icon: "warning",
        title: "Warning",
        text: `You cannot remove this user as there are assigned questions: ${assignedDetails}`,
      });
    } else {
      setOrgChartData((prevData) => {
        const updatedData = { ...prevData };

        if (parentNode && parentNode.children && foundNode) {
          parentNode.children = parentNode.children.filter(
            (child) => child.id !== nodeId
          );
          const newDataVariable = { ...prevData };

          saveChart(newDataVariable, null, null, null);
          return newDataVariable;
        }
        const newDataVariable = { ...prevData };

        saveChart(newDataVariable, null, null, null);

        return prevData;
      });
    }
  };

  const findParentById = (node, targetId, parent = null) => {
    if (node.id === targetId) {
      return { parentNode: parent, foundNode: node };
    }

    if (node.children) {
      for (const child of node.children) {
        const result = findParentById(child, targetId, node);
        if (result.foundNode) {
          return result;
        }
      }
    }

    return { parentNode: null, foundNode: null };
  };

  const findNodeById = (node, targetId) => {
    if (node.id === targetId) {
      return node;
    }

    if (node.children) {
      for (const child of node.children) {
        const foundNode = findNodeById(child, targetId);
        if (foundNode) {
          return foundNode;
        }
      }
    }

    return null;
  };

  const MyNodeComponent = ({ node, path = [] }) => {
    const isEvenLevel = node.level % 2 === 0;
    const [addRoleOfReportee, setAddRoleOfReportee] = useState(false);
    const [reAssignRoleModal, setReAssignRoleModal] = useState(false);
    const [auditAnswerPopup, setAuditAnswerPopup] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [subUserId, setSubUserId] = useState(null);
    const [roleId, setRoleId] = useState(null);
    const [addReporteePopup, setAddReporteePopup] = useState(false);
    const [reAssignUserModal, setReAssignUserModal] = useState(false);
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const handleReassignUser = (nodeId) => {
      const { parentNode, foundNode } = findParentById(orgChartData, nodeId);
      if (foundNode?.roleId === null) {
        setReAssignUserModal(true);
        getSubUser(parentNode?.roleId);
      } else {
        setReAssignRoleModal(true);
        roleManagementList();
      }
    };

    const handleToggleDropdown = (nodeId) => {
      // Toggle the dropdown state for the clicked node
      setOpenDropdowns((prevState) => ({
        ...prevState,
        [nodeId]: !prevState[nodeId],
      }));
    };
    const findRoleIds = async (obj, result = []) => {
      if (obj.roleId !== null && obj.roleId !== undefined) {
        result.push(obj.roleId);
      }
      if (obj.children && obj.children.length > 0) {
        obj.children.forEach((child) => findRoleIds(child, result));
      }
      return result;
    };
    const findUserIds = async (obj, result = []) => {
      if (obj.userId !== null && obj.userId !== undefined) {
        result.push(obj.userId);
      }
      if (obj.children && obj.children.length > 0) {
        obj.children.forEach((child) => findUserIds(child, result));
      }
      return result;
    };
    const openAddRoleOfReportee = () => {
      setAddRoleOfReportee(true);
      roleManagementList();
    };
    const closeAddRoleOfReportee = () => {
      setAddRoleOfReportee(false);
    };
    const closeAuditPopUp = () => {
      setAuditAnswerPopup(false);
    };

    const openAddReporteePopup = (roleId) => {
      getSubUser(roleId);
      setAddReporteePopup(true);
    };
    const closeAddReporteePopup = () => {
      setAddReporteePopup(false);
      setReAssignUserModal(false);
    };

    const roleManagementList = async () => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getMasterData`,
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        const roleIdArray = await findRoleIds(orgChartData);

        const filteredArray = data?.data?.filter(
          (obj) => !roleIdArray.includes(obj.id)
        );
        console.log(data?.data, orgChartData, filteredArray);
        setRoleList(filteredArray?.reverse());
      }
    };
    const getAuditPermission = async () => {
      const auditor = JSON.parse(subUserId).auditor;
      if (auditor) {
        setAuditAnswerPopup(true);
      } else {
        handleAddChild(
          node.id,
          node.level,
          JSON.parse(roleId),
          JSON.parse(subUserId)
        );
      }

      closeAddReporteePopup();
    };
    const getSubUser = async (roleId) => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getSubUserBasedOnRoleId`,
        {},
        { roleId: roleId },
        "GET"
      );
      if (isSuccess) {
        const roleIdArray = await findUserIds(orgChartData);
        const filteredArray = data?.data?.filter(
          (obj) => !roleIdArray.includes(obj.userId)
        );
        setUserList(filteredArray);
      }
    };

    return (
      <div className="initechNode">
        <div className="d-flex align-items-center gap-3">
          <div>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 50,
                backgroundColor: "rgb(85 121 144)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 20,
                color: "#fff",
              }}
            >
              {isEvenLevel
                ? node.name
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase())
                    .join("")
                : node.actor
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase())
                    .join("")}
            </div>
          </div>

          <div className="text-start">
            {!isEvenLevel ? openAddRoleOfReportee : openAddReporteePopup}{" "}
            {isEvenLevel ? (
              <p className="m-0">
                <b style={{ fontSize: 15 }}>{node.name}</b>
              </p>
            ) : (
              <p className="m-0">
                <b style={{ fontSize: 12 }}>{node.actor}</b>
              </p>
            )}
          </div>

          {!isEvenLevel ? (
            node.children.length > 0 ? (
              !(userId === node.userId) && <button
                className="cross_window"
                title="Options"
                onClick={() => handleToggleDropdown(node.id)}
              >
                <i className="fas fa-ellipsis-v"></i>
                {openDropdowns[node.id] && (
                  <div className="dropdown-content">
                    {userPermissionList.some(
                      (permission) =>
                        permission.permissionCode === "REASSIGN_USER"
                    ) && (
                      <a onClick={() => handleReassignUser(node.id)}>
                        Reassign User
                      </a>
                    )}
                  </div>
                )}
              </button>
            ) : (
             !(userId === node.userId) && <><button
                className="cross_window"
                title="Options"
                onClick={() => handleToggleDropdown(node.id)}
              >
                <i className="fas fa-ellipsis-v"></i>
                {openDropdowns[node.id] && (
                  <div className="dropdown-content">
                    <>
                      {userPermissionList.some(
                        (permission) =>
                          permission.permissionCode === "REMOVE_USER"
                      ) && (
                        <a onClick={() => handleRemoveNode(node.id)}>
                          Remove User
                        </a>
                      )}
                      {userPermissionList.some(
                        (permission) =>
                          permission.permissionCode === "REASSIGN_USER"
                      ) && (
                        <a onClick={() => handleReassignUser(node.id)}>
                          Reassign User
                        </a>
                      )}
                    </>
                  </div>
                )}
              </button> </>
            )
          ) : node.children.length > 0 ? (
            <></>
          ) : (
            <button
              className="cross_window"
              title="Options"
              onClick={() => handleToggleDropdown(node.id)}
            >
              <i className="fas fa-ellipsis-v"></i>
              {openDropdowns[node.id] && (
                <div className="dropdown-content">
                  <>
                    {userPermissionList.some(
                      (permission) =>
                        permission.permissionCode === "REMOVE_USER"
                    ) && (
                      <a onClick={() => handleRemoveNode(node.id)}>
                        Remove Role
                      </a>
                    )}
                    {userPermissionList.some(
                      (permission) =>
                        permission.permissionCode === "REASSIGN_USER"
                    ) && (
                      <a onClick={() => handleReassignUser(node.id)}>
                        Update Role 
                      </a>
                    )}
                  </>
                </div>
              )}
            </button>
          )}
        </div>

        {userPermissionList.some(
          (permission) => permission.permissionCode === "CREATE_CHART"
        ) && (
          <button
            className="add_reportee"
            onClick={
              !isEvenLevel
                ? openAddRoleOfReportee
                : () => openAddReporteePopup(node?.roleId)
            }
            title={isEvenLevel ? "Add Role" : "Add Reportee"}
          >
            +
          </button>
        )}

        <>
          <Modal
            show={addRoleOfReportee}
            onHide={() => closeAddRoleOfReportee()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="form-group pb-3">
                  <select
                    className="select_one industrylist"
                    onChange={(e) => {
                      setRoleId(e.target.value);
                    }}
                  >
                    <option value="">Select Role</option>
                    {roleList?.length > 0 &&
                      roleList?.map((data, index) => {
                        const optionValue = JSON.stringify({
                          id: data?.id,
                          roleName: data?.role_name,
                        });

                        return (
                          <option key={index} value={optionValue}>
                            {data?.role_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() => closeAddRoleOfReportee()}
              >
                Cancel
              </Button>
              <Button
                variant="info"
                onClick={() => {
                  handleAddChild(node.id, node.level, JSON.parse(roleId));
                  closeAddRoleOfReportee();
                }}
              >
                Add Role
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={reAssignRoleModal}
            onHide={() => closeAddRoleOfReportee()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="form-group pb-3">
                  <select
                    className="select_one industrylist"
                    onChange={(e) => {
                      setRoleId(e.target.value);
                    }}
                  >
                    <option value="">Select Role</option>
                    {roleList?.length > 0 &&
                      roleList?.map((data, index) => {
                        const optionValue = JSON.stringify({
                          id: data?.id,
                          roleName: data?.role_name,
                        });

                        return (
                          <option key={index} value={optionValue}>
                            {data?.role_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() => closeAddRoleOfReportee()}
              >
                Cancel
              </Button>
              <Button
                variant="info"
                onClick={() => {
                  updateUserList(
                    node.id,

                    JSON.parse(roleId)
                  );
                  closeAddRoleOfReportee();
                }}
              >
                Update Role
              </Button>
            </Modal.Footer>
          </Modal>
        </>

        <>
          <Modal show={auditAnswerPopup} onHide={() => closeAuditPopUp()}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Do you want to Audit Answer</h5>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() => {
                  handleAddChild(
                    node.id,
                    node.level,
                    JSON.parse(roleId),
                    JSON.parse(subUserId),
                    "NO"
                  );
                  closeAuditPopUp();
                }}
              >
                No
              </Button>
              <Button
                variant="info"
                onClick={() => {
                  handleAddChild(
                    node.id,
                    node.level,
                    JSON.parse(roleId),
                    JSON.parse(subUserId),
                    "YES"
                  );
                  closeAuditPopUp();
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={addReporteePopup} onHide={() => closeAddReporteePopup()}>
            <Modal.Header closeButton>
              <Modal.Title>Add Reportee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="form-group pb-3">
                  <select
                    className="select_one industrylist"
                    onChange={(e) => {
                      setSubUserId(e.target.value);
                    }}
                  >
                    <option value="">Select User</option>
                    {userList?.length > 0 &&
                      userList?.map((data, index) => {
                        const optionValue = JSON.stringify({
                          id: data?.userId,
                          name: data?.firstName + " " + data?.lastName,
                          email: data.emailId,
                          auditor: data?.auditor,
                        });
                        return (
                          <option key={index} value={optionValue}>
                            {data?.firstName + " " + data?.lastName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() => closeAddReporteePopup()}
              >
                Cancel
              </Button>
              <Button variant="info" onClick={() => getAuditPermission()}>
                Add Reportee
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={reAssignUserModal}
            onHide={() => closeAddReporteePopup()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="form-group pb-3">
                  <select
                    className="select_one industrylist"
                    onChange={(e) => {
                      setSubUserId(e.target.value);
                    }}
                  >
                    <option value="">Select User</option>
                    {userList?.length > 0 &&
                      userList?.map((data, index) => {
                        const optionValue = JSON.stringify({
                          id: data?.userId,
                          name: data?.firstName + " " + data?.lastName,
                          email: data.emailId,
                          auditor: data?.auditor,
                        });
                        return (
                          <option key={index} value={optionValue}>
                            {data?.firstName + " " + data?.lastName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() => closeAddReporteePopup()}
              >
                Cancel
              </Button>
              <Button
                variant="info"
                onClick={() => {
                  updateUserList(
                    node.id,

                    JSON.parse(subUserId)
                  );
                  closeAddReporteePopup();
                }}
              >
                Add Reportee
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    );
  };

  const saveChart = async (orgChartData, userId, parentUserId, audit) => {
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `createOrgChart`,
      {},
      {
        orgChart: JSON.stringify(orgChartData),
        validateAudit: audit,
        parentUserId: parentUserId,
        userId: userId,
      },
      "POST"
    );
    if (isSuccess) {
    }
  };

  return (
    <div>
      <div className="Introduction chart_section framwork_2">
        <OrgChart
          tree={orgChartData}
          NodeComponent={(props) => <MyNodeComponent {...props} />}
        />
      </div>
    </div>
  );
};

export default FlowCharts;
