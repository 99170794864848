import React, { useEffect, useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { Col, Form, Modal, ProgressBar, Row, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SummeryGraph from "../home/SummeryGraph";
import MyDisclosureProgressComponent from "./DashboardGraph/MyDisclosureProgressComponent";
import FrameworkComponent from "../home/FrameworkComponent";
import SubAdminComponent from "../home/SubAdminComponent";
import FocusAreaComponent from "../home/FocusAreaComponent";
import QuestionTypeComponent from "../home/QuestionTypeComponent";
import Multiselect from "multiselect-react-dropdown";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import NoNotification from "../../../../img/no-results.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ComapreQuestionTypeComponent from "../home/CompareQuestionType";
import EnvironmentComponent from "../home/EnvironmentComponent";
import ComapreLineChartComponent from "../home/CompareLinechart";
import TotalProductWise from "../home/TotalProductWise";
import TimewiseProductWise from "../home/TimewiseProductMix";
const Dashboard = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const firstName = currentUser?.first_name;
  const lastName = currentUser?.last_name;
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [questionCatqoryList, setQuestionCatqoryList] = useState([]);
  const [questionScopesList, setQuestionScopesList] = useState([]);
  const [recoveredWasteGraph, setRecoveredWasteGraph] = useState(null);
  const [wasteGraph, setWasteGraph] = useState(null);
  const [disposedWasteGraph, setDisposedWasteGraph] = useState(null);
  const [totalWasteGraph, setTotalWasteGraph] = useState(null);
  const [sefety, setSefety] = useState(null);
  const [training, setTraining] = useState(null);
  const [employeeTraining, setEmployeeTraining] = useState(null);
  const [workerTraining, setWorkerTraining] = useState(null);
  const [totalCategoryConsumption, setTotalCategoryConsumption] = useState(null);
  const [compareQuestionCatqoryList, setCompareQuestionCatqoryList] = useState(
    []
  );
  const [compareLineQuestionCatqoryList, setCompareLineQuestionCatqoryList] =
    useState([]);
  const [compareLineQuestionScopeList, setCompareLineQuestionScopeList] =
    useState([]);
  const [compareQuestionScopesList, setCompareQuestionScopesList] = useState(
    []
  );
  const [totalProductWise, setTotalProductWise] = useState([]);
  const [totalScope1AndScope2, setTotalScope1AndScope2] = useState([]);
  const [scope1AndScope2, setScope1AndScope2] = useState([]);
  const [locationBasedEmissionAcrossYear, setLocationBasedEmissionAcrossYear] = useState([]);
  
  const [sectorQuestionScope, setSectorQuestionScope] = useState([]);
  
  const [timewiseProductWise, setTimewiseProductWise] = useState([]);
  const [totalLocationwise, setTotalLocationwise] = useState([]);  
  const [locationwiseProductWise, setLocationwiseProductWise] = useState([]);
  const [locationwiseTimeWise, setLocationwiseTimeWise] = useState([]);  
  const [permissionList, setPermissionList] = useState([]);
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [todaysActivities, setTodaysActivities] = useState([]);
  const [teamWorkloadData, setTeamWorkloadData] = useState([]);
  const [scope2AcrossYear, setScope2AcrossYear] = useState([]);
  
  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLastWeekAcitivities(data?.data);
    }
  };

  const todaysActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}todaysActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTodaysActivities((data?.data).reverse());
    }
  };

  const teamWorkloadProgess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTeamWorkloadData(data?.data?.teamWorkloadResults);
    }
  };

  const getTotalWasteGraph = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTotalWasteGraph`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTotalWasteGraph(data?.data);
    }
  };
  const getWasteGraph = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getWasteGraph`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setWasteGraph(data?.data);
    }
  };

  const getWasteRecovered = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getWasteRecovered`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setRecoveredWasteGraph(data?.data);
    }
  };

  const getWasteDisposed = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getWasteDisposed`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setDisposedWasteGraph(data?.data);
    }
  };

  const getSefetyGraph = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSefetyGraph`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setSefety(data?.data);
    }
  };

  const getTrainingGraph = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTrainingGraph`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTraining(data?.data);
    }
  };

  const getEmployeeTrainingGraph = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getEmployeeTrainingGraph`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setEmployeeTraining(data?.data);
    }
  };

  const getWorkerTrainingGraph = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getWorkerTrainingGraph`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setWorkerTraining(data?.data);
    }
  };

  const questionCatqoryProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}questionCatqoryProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setQuestionCatqoryList(data?.data);
    }
  };

  const getSectorQuestionScopes = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSectorQuestionScopes`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setQuestionScopesList(data?.data);
    }
  };

  const compareQuestionCatqoryProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}compareQuestionCatqoryProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setCompareQuestionCatqoryList(data?.data);
    }
  };
  const getCompareQuestionCatqoryForLineProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getCompareQuestionCatqoryForLineProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setCompareLineQuestionCatqoryList(data?.data);
    }
  };

  const getTotalQuestionCatqoryProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTotalQuestionCatqoryProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTotalCategoryConsumption(data?.data);
    }
  };
  
  const compareQuestionScopeForLineProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getCompareQuestionScopeForLineProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setCompareLineQuestionScopeList(data?.data);
    }
  };
  const compareGetSectorQuestionScopes = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}compareGetSectorQuestionScopes`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setCompareQuestionScopesList(data?.data);
    }
  };
  const getTotalComparePieChartsProgress = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTotalComparePieChartsProgress`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTotalProductWise(data?.data);
    }
  };
  const getSectorQuestionScope = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTotalComparePieChartsCorbonEmission`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setSectorQuestionScope(data?.data);
    }
  };
  
  const getTotalScope1AndScope2 = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTotalScope1AndScope2`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTotalScope1AndScope2(data?.data);
    }
  };
  const getTimewiseProductMix = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTimewiseProductMix`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTimewiseProductWise(data?.data);
    }
  };
  const getLocationwiseProductMix = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getLocationwiseProductMix`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLocationwiseProductWise(data?.data);
    }
  };
  const getTimewiseLocationMix = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTimewiseLocationMix`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLocationwiseTimeWise(data?.data);
    }
  };
  const getTotalBasedOnLocation = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTotalBasedOnLocation`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setTotalLocationwise(data?.data);
    }
  };
  const getScope2AcrossYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getScope2AcrossYear`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setScope2AcrossYear(data?.data);
    }
  };
  const getScope1AnsScope2 = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getScope1AnsScope2`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setScope1AndScope2(data?.data);
    }
  };
  const getLocationBasedEmissionAcrossYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getLocationBasedEmissionAcrossYear`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLocationBasedEmissionAcrossYear(data?.data);
    }
  };
  
  const handleApplyFilters = async (graphType, filter, type) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveGraphFilter`,
      {},
      { graphName: graphType, filter: JSON.stringify(filter) },
      "POST"
    );
    if (isSuccess) {
      if (type === "SCOPE") {
        getSectorQuestionScopes();
      } else if (type === "CATEGORIES") {
        questionCatqoryProgress();
      }
      if (type === "COMPARECATEGORIES") {
        compareQuestionCatqoryProgress();
      } else if (type === "COMPARESCOPE") {
        compareGetSectorQuestionScopes();
      } else if (type === "COMPARELINE") {
        getCompareQuestionCatqoryForLineProgress();
      } else if (type === "COMPARESCOPELINE") {
        compareQuestionScopeForLineProgress();
      }else if (type === "PRODUCTWISEPIECHART") {
        getTotalComparePieChartsProgress();
      }
    }
  };

  const onSelect = (data) => {
    localStorage.setItem("questionIds", data);
    window.location.href = "/#/sector_questions";
  };
  const [tab, setTab] = useState("Progress");

  const handleSelectTab = (eventKey) => {
    if (eventKey === "Progress") setTab("Progress");
    if (eventKey === "Environment") setTab("Environment");
    if (eventKey === "Energy") setTab("Energy");
    if (eventKey === "Emission") setTab("Emission");
    if (eventKey === "Water") setTab("Water");
    if (eventKey === "Diversity") setTab("Diversity");
    if (eventKey === "Waste") setTab("Waste");
    if (eventKey === "Safety") setTab("Safety");
    if (eventKey === "Training") setTab("Training");
  };

  useEffect(() => {
    const dashboardMenu = JSON.parse(localStorage.getItem("menu"));
    const dashboardObject = dashboardMenu.find(
      (item) => item.caption === "Dashboard"
    ).permissions;
    setPermissionList(dashboardObject);
    lastWeekActivity();
    todaysActivity();
    teamWorkloadProgess();
    questionCatqoryProgress();
    getSectorQuestionScopes();
    compareQuestionCatqoryProgress();
    compareGetSectorQuestionScopes();
    compareQuestionScopeForLineProgress();
    getWasteGraph();
    getWasteRecovered();
    getWasteDisposed();
    getSefetyGraph();
    getTrainingGraph();
    getTotalWasteGraph();
    getEmployeeTrainingGraph();
    getWorkerTrainingGraph();
    getCompareQuestionCatqoryForLineProgress();
    getTotalQuestionCatqoryProgress();
    getTotalComparePieChartsProgress();
    getTimewiseProductMix();
    getLocationwiseProductMix();
    getTimewiseLocationMix();
    getTotalBasedOnLocation();
    getSectorQuestionScope();
    getScope2AcrossYear();
    getTotalScope1AndScope2();
    getScope1AnsScope2();
    // getLocationBasedEmissionAcrossYear();
  }, []);
  return (
    <div>
      <Sidebar dataFromParent={props.location.pathname} />
      <Header />
      <div className="main_wrapper">
        <div className="inner_wraapper p-3">
          <div className="text-center mb-4">
            <h3 style={{ color: "var(--primaryColor)" }}>
              <b>{lastWeekAcitivities?.message}</b>
            </h3>
            <p>
            Monitor your report progress here
            </p>
          </div>

          <div className="color_div_Current mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="color_rent mb-0">
                <h6 className="home_text">Download Your Full ESG Report</h6>
              </div>
              <div className="color_rent mb-0">
                <h6 className="home_text">FY - 2023-2024</h6>
              </div>
              <button
                disabled={true}
                onClick={() => {
                  setShow(true);
                }}
                className="new_button_style"
              >
                Download
              </button>
            </div>
          </div>
          <div>
            <div className="inner_wraapper  pt-3">
              <Tabs
                defaultActiveKey="Progress"
                id="uncontrolled-tab-example"
                className="Setting_tabs"
                onSelect={(eventKey) => handleSelectTab(eventKey)}
              >
                {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "STATUS_OVERVIEW" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Progress" title="Progress">
                    {tab === "Progress" && (
                      <Row>
                        <Col
                          xl={3}
                          lg={3}
                          md={6}
                          className={`my-3 ${
                            lastWeekAcitivities?.done?.questionId.length === 0
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() =>
                            lastWeekAcitivities?.done?.questionId.length !==
                              0 &&
                            onSelect(lastWeekAcitivities?.done?.questionId)
                          }
                        >
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center done">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>
                                    {lastWeekAcitivities?.done?.number} Done
                                  </b>
                                </h4>
                                <p>In the Last 7 Days</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col
                          xl={3}
                          lg={3}
                          md={6}
                          className={`my-3 ${
                            lastWeekAcitivities?.updated?.questionId.length ===
                            0
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() =>
                            lastWeekAcitivities?.updated?.questionId.length !==
                              0 &&
                            onSelect(lastWeekAcitivities?.updated?.questionId)
                          }
                        >
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center update">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-pen"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>
                                    {lastWeekAcitivities?.updated?.number}{" "}
                                    Updated
                                  </b>
                                </h4>
                                <p>In the Last 7 Days</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xl={3}
                          lg={3}
                          md={6}
                          className={`my-3 ${
                            lastWeekAcitivities?.pending?.questionId.length ===
                            0
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() =>
                            lastWeekAcitivities?.pending?.questionId.length !==
                              0 &&
                            onSelect(lastWeekAcitivities?.pending?.questionId)
                          }
                        >
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center create">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>
                                    {lastWeekAcitivities?.pending?.number}{" "}
                                    Defaulted
                                  </b>
                                </h4>
                                <p>In the Last 7 Days</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xl={3}
                          lg={3}
                          md={6}
                          className={`my-3 ${
                            lastWeekAcitivities?.due?.questionId.length === 0
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() =>
                            lastWeekAcitivities?.due?.questionId.length !== 0 &&
                            onSelect(lastWeekAcitivities?.due?.questionId)
                          }
                        >
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center due">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fas fa-calendar-alt"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>{lastWeekAcitivities?.due?.number} Due</b>
                                </h4>
                                <p>In the Next 7 Days</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode === "STATUS_OVERVIEW" &&
                            permission.checked
                        ) && (
                          <Col xl={6} lg={6} md={12} className="mb-3">
                            <div
                              className="bg-white widget-style1 summery__widget"
                              style={{ position: "relative" }}
                            >
                              <h5 className="p-0">
                                <b>My Disclosure Progress</b>
                              </h5>
                              {/* <p>
                                Get a snapshot of the status of your items.{" "}
                              </p> */}
                              <FrameworkComponent />
                            </div>
                          </Col>
                        )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode === "RECENT_ACTIVITY" &&
                            permission.checked
                        ) && (
                          <Col xl={6} lg={6} md={12} className="mb-3">
                            <div className="bg-white widget-style1 summery__widget">
                              <h5 className="p-0">
                                <b>Recent Activity</b>
                              </h5>
                              <p>
                                Stay up to date with what's happening across the
                                project.
                              </p>
                              <div className="activity_section">
                                {todaysActivities.length > 0 ? (
                                  todaysActivities.map((notification) => (
                                    <div
                                      key={notification.id}
                                      className="d-flex flex-wrap mb-2"
                                    >
                                      <div className="circle-icon">
                                        <div className="name_icon">
                                          {firstName.charAt(0)}
                                        </div>
                                      </div>
                                      <div className="widget-data">
                                        <p className="m-0">
                                          {`${firstName} ${lastName}`}{" "}
                                          <NavLink
                                            to={{
                                              pathname: "/sector_questions",

                                              state: {
                                                questionIds: [
                                                  notification?.questionId,
                                                ],
                                              },
                                            }}
                                          >
                                            <span title={notification?.massage}>
                                              {notification?.massage.length > 30
                                                ? `${notification?.massage.slice(
                                                    0,
                                                    68
                                                  )}.....`
                                                : notification?.massage}
                                            </span>
                                          </NavLink>{" "}
                                          {notification?.status}
                                        </p>

                                        <span>
                                          {new Date(
                                            notification.createdAt
                                          ).toLocaleString()}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-center w-100 h-75">
                                    {" "}
                                    <img
                                      src={NoNotification}
                                      alt="No Notification Here..."
                                      className="w-50 h-100"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "MY_DISCLOSURE_PROGRESS" && permission.checked
                        ) && (
                          <Col xl={6} lg={6} md={12} className="mb-3">
                          <div
                            className="bg-white widget-style1 summery__widget"
                            style={{ position: "relative" }}
                          >
                            <h5 className="p-0">
                              <b>Question Category Progress</b>
                            </h5>
                            {/* <p>
                              Get a snapshot of the status of your items.{" "}
                            </p> */}
                            <SummeryGraph />
                          </div>
                        </Col>
                          // <Col xl={6} lg={6} md={12} className="mb-3">
                          //   <div className="bg-white widget-style1 p-0 summery__widget">
                          //     < />
                          //   </div>
                          // </Col>
                        )}
                        {/* {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "FRAMEWORK_PROGRESS" && permission.checked
                        ) && (
                          <Col xl={6} lg={6} md={12} className="mb-3">
                            <div className="bg-white widget-style1 p-0 summery__widget">
                              <FrameworkComponent />
                            </div>
                          </Col>
                        )} */}
                        {/* {permissionList.some(
                            (permission) =>
                              permission.permissionCode === "USER_PROGRESS" &&
                              permission.checked
                          ) && (
                              <Col xl={6} lg={6} md={12} className="mb-3">
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  <SubAdminComponent />
                                </div>
                              </Col>
                            )} */}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode === "TEAM_WORKLOAD" &&
                            permission.checked
                        ) && (
                          <Col xl={6} lg={6} md={12} className="mb-3">
                            <div className="bg-white widget-style1">
                              <h5 className="p-0">
                                <b>Team Workload</b>
                              </h5>
                              <p>Oversee the capacity of your team.</p>
                              <div className="activity_section">
                                <Table>
                                  <thead>
                                    <th style={{ width: 200 }}>Assignee</th>
                                    <th>Work Distribution</th>
                                    <th>Count</th>
                                  </thead>
                                  <tbody>
                                    {teamWorkloadData.length > 0 ? (
                                      teamWorkloadData.map((data) => {
                                        return (
                                          <tr key={data.userId}>
                                            <td>
                                              <div className="hstack">
                                                <div className="circle-icon">
                                                  <div
                                                    className="name_icon m-0"
                                                    title={data.email}
                                                  >
                                                    {data?.firstName.charAt(0) +
                                                      data?.lastName.charAt(0)}
                                                  </div>
                                                </div>
                                                <div className="widget-data">
                                                  <p
                                                    className="m-0"
                                                    title={data.email}
                                                  >
                                                    {`${data.firstName} ${data.lastName}`}
                                                  </p>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <ProgressBar
                                                variant="warning"
                                                color="#008000"
                                                now={
                                                  isNaN(
                                                    data?.percentageAccepted
                                                  )
                                                    ? 0
                                                    : data?.percentageAccepted
                                                }
                                                label={`${
                                                  isNaN(
                                                    data?.percentageAccepted
                                                  )
                                                    ? "0"
                                                    : data?.percentageAccepted
                                                }%`}
                                              />
                                            </td>
                                            <td>
                                              <NavLink
                                                to={{
                                                  pathname: "/sector_questions",
                                                  state: {
                                                    questionIds: [
                                                      data?.questionIds,
                                                    ],
                                                  },
                                                }}
                                              >
                                                 {data?.accepted}/{data?.totalQuestions}
                                              </NavLink>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <p>No Data Found</p>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                              <div className="legend-container">
                                <div className="legend-item accepted">
                                  <span className="color-box accepted-color"></span>
                                  <span>Accepted</span>
                                </div>
                                <div className="legend-item rejected">
                                  <span className="color-box rejected-color"></span>
                                  <span>Rejected</span>
                                </div>
                                <div className="legend-item responded">
                                  <span className="color-box responded-color"></span>
                                  <span>Answered</span>
                                </div>
                                <div className="legend-item not-responded">
                                  <span className="color-box not-responded-color"></span>
                                  <span>Not Responded</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode === "SOURCE_PROGRESS" &&
                            permission.checked
                        ) && (
                          <Col xl={6} lg={6} md={12} className="mb-3">
                            <div className="bg-white widget-style1 p-0 summery__widget">
                              <FocusAreaComponent />
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Tab>
                )}
                {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "STATUS_OVERVIEW" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Environment" title="Environment">
                    {tab === "Environment" && (
                      <Row>
                        <Col xl={3} lg={3} md={6} className="my-3">
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center done">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-battery-full"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>1.73 M</b>
                                </h4>
                                <p>Total Energy (In GJ)</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} className="my-3">
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center update">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-cloud"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>288.09K</b>
                                </h4>
                                <p>Total Emission (In KgCo2e)</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} className="my-3">
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center create">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-tint"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>1.96M</b>
                                </h4>
                                <p>Total Water Consumed (In KL)</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} className="my-3">
                          <div className="bg-white widget-style1">
                            <div className="d-flex flex-wrap align-items-center due">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>6.45K</b>
                                </h4>
                                <p>Total Waste Disposed (In MT)</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Top Energy Consuming Sites"
                              type="bar"
                              data={{
                                series: [
                                  {
                                    data: [1000, 530, 148],
                                  },
                                ],
                                categories: ["Noida", "Sector16", "Rohi"],
                              }}
                            />
                          </div>
                        </Col>{" "}
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Top Emissions Contrbuting Sites"
                              type="bar"
                              data={{
                                series: [
                                  {
                                    data: [700, 430, 248],
                                  },
                                ],
                                categories: ["Noida", "Sector16", "Rohi"],
                              }}
                            />
                          </div>
                        </Col>{" "}
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Top Water Consuming Sites"
                              type="bar"
                              data={{
                                series: [
                                  {
                                    data: [600, 530, 448],
                                  },
                                ],
                                categories: ["Noida", "Sector16", "Rohi"],
                              }}
                            />
                          </div>
                        </Col>{" "}
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Top Waste Disposal Sites"
                              type="area"
                              data={{
                                series: [
                                  {
                                    name: "Waste Disposed",
                                    data: [100, 70, 50],
                                  },
                                ],
                                categories: ["Noida", "Sector16", "Rohi"],
                              }}
                            />
                          </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Renewable And Non-Renewable Energy Mix"
                              type="pie"
                              data={{
                                series: [80, 20],
                                categories: ["Renewable", "Non-Renewable"],
                              }}
                            />
                          </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Scope 1 And Scope 2 Emissions"
                              type="donut"
                              data={{
                                series: [350, 55],
                                categories: ["Scope 1", "Scope 2"],
                              }}
                            />
                          </div>
                        </Col>{" "}
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Total Water Withdrawn And Discharged"
                              type="pie"
                              data={{
                                series: [90, 10],
                                categories: [
                                  "Water Withdrawn",
                                  "Water Discharged",
                                ],
                              }}
                            />
                          </div>
                        </Col>{" "}
                        <Col xl={3} lg={3} md={6} className="mb-3">
                          <div className="bg-white widget-style1 p-0 environment__widget">
                            <EnvironmentComponent
                              Heading="Waste Disposed By Type"
                              type="donut"
                              data={{
                                series: [320, 55],
                                categories: ["Waste A", "Waste B"],
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Tab>
                )} 
                 {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Energy" title="Energy">
                    {tab === "Energy" && (
                      <Row>
                        <Col xl={4} lg={4} md={6} className="my-3">
                          <div className="bg-white widget-style1 d-flex justify-content-center">
                            <div className="d-flex flex-wrap align-items-center update">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-battery-full"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>1.73 M</b>
                                </h4>
                                <p>Total Energy (In GJ)</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col xl={4} lg={4} md={6} className="my-3">
                          <div className="bg-white widget-style1 d-flex justify-content-center">
                            <div className="d-flex flex-wrap align-items-center done">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-leaf"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>0.63 M</b>
                                </h4>
                                <p>Total Renewable Energy (In GJ)</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col xl={4} lg={4} md={6} className="my-3">
                          <div className="bg-white widget-style1 d-flex justify-content-center">
                            <div className="d-flex flex-wrap align-items-center due">
                              <div className="circle-icon">
                                <div className="icon">
                                  <i
                                    className="fa fa-fire"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>1.07 M</b>
                                </h4>
                                <p>Total Non-Renewable Energy (In GJ)</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {totalCategoryConsumption?.length > 0 &&
                          totalCategoryConsumption.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Total Energy Consumption" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <QuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="CATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                             {totalProductWise?.length > 0 &&
                          totalProductWise.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Energy Consumption ProductWise" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <TotalProductWise
                                            key={key}
                                            category={"Product-Wise Energy Consumption"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                           {totalLocationwise?.length > 0 &&
                          totalLocationwise.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Energy Consumption Locationwise" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") 
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={"Location-Wise Energy Consumption"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                            {timewiseProductWise?.length > 0 &&
                          timewiseProductWise.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Energy Consumption TimeWise ProductMix" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") 
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <TimewiseProductWise
                                            key={key}
                                            category={"Time-Based Product-Mix"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                            {locationwiseProductWise?.length > 0 &&
                          locationwiseProductWise.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Energy Consumption LocationWise ProductMix" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") 
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <TimewiseProductWise
                                            key={key}
                                            category={"Location-Based Product-Mix"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                             {locationwiseTimeWise?.length > 0 &&
                          locationwiseTimeWise.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Energy Consumption TimeWise LocationMix" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") 
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={"Energy Consumption Across Location"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                          
                        {/* {questionCatqoryList?.length > 0 &&
                          questionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Energy Consumption" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <QuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="CATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )} */}
                             {compareLineQuestionCatqoryList?.length > 0 &&
                          compareLineQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Compare Overall Energy Consumption" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") 
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={"Energy Consumption Across Year"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARELINE"                                        
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {/* {compareQuestionCatqoryList?.length > 0 &&
                          compareQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length ? null : (
                              <Col
                                key={index}
                                xl={6}
                                lg={6}
                                md={12}
                                className="mb-3"
                              >
                                {Object.keys(item).map((key) => {
                                  if (
                                    key === "Compare Energy Consumption" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <div className="bg-white widget-style1 p-0 summery__widget">
                                        <ComapreQuestionTypeComponent
                                          key={key}
                                          category={key}
                                          data={item[key]}
                                          handleApplyFilters={
                                            handleApplyFilters
                                          }
                                          type="COMPARECATEGORIES"
                                          enable={false}
                                        />
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </Col>
                            )
                          )} */}
                        
                     
                      </Row>
                    )}
                  </Tab>
                )} 

                 {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Emission" title="Emission">
                    {tab === "Emission" && (
                      <Row>
                          <Col xl={4} lg={4} md={6} className="my-3">
                          <div className="bg-white widget-style1 d-flex justify-content-center">
                            <div className="d-flex flex-wrap align-items-center update">
                              <div className="circle-icon">
                                <div className="icon">
                                <i className="fas fa-cloud" aria-hidden="true"></i>

                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>1.24 M</b>
                                </h4>
                                <p>Total Emissions (In kgco2e)</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col xl={4} lg={4} md={6} className="my-3">
                          <div className="bg-white widget-style1 d-flex justify-content-center">
                            <div className="d-flex flex-wrap align-items-center done">
                              <div className="circle-icon">
                                <div className="icon">
                                <i className="fas fa-industry" aria-hidden="true"></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>1.09 M</b>
                                </h4>
                                <p>Scope 1 (In kgco2e)</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col xl={4} lg={4} md={6} className="my-3">
                          <div className="bg-white widget-style1 d-flex justify-content-center">
                            <div className="d-flex flex-wrap align-items-center due">
                              <div className="circle-icon">
                                <div className="icon">
                          
                                <i className="fas fa-lightbulb" aria-hidden="true"></i>
                                </div>
                              </div>
                              <div className="widget-data">
                                <h4>
                                  <b>0.15 M</b>
                                </h4>
                                <p>Scope 2 (In kgco2e)</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {totalCategoryConsumption?.length > 0 &&
                          totalCategoryConsumption.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Total Energy Consumption" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <QuestionTypeComponent
                                            key={key}
                                            category={"Total Corbon Emission"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="CATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                          
                            {scope1AndScope2?.length > 0 &&
                          scope1AndScope2.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                console.log( (key === "Scope1 And Scope 2" &&
                                item[key] &&
                                item[key].hasOwnProperty("series") &&
                                item[key].hasOwnProperty("categories")),"itemitemitemitemitemitem",key,item[key],item[key].hasOwnProperty("series") ,item[key].hasOwnProperty("categories"))
                                  if (
                                    key === "Scope 1 And Scope 2" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (

                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <TotalProductWise
                                            key={key}
                                            category={"Scope 1 And Scope 2"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                           {totalLocationwise?.length > 0 &&
                          totalLocationwise.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "GHG Emissions Locationwise" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") 
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={"Emission Across Location"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                          
                          {sectorQuestionScope?.length > 0 &&
                          sectorQuestionScope.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Scope 1 ProductWise" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <TotalProductWise
                                            key={key}
                                            category={"Product-Wise Emission Scope1"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                            {sectorQuestionScope?.length > 0 &&
                          sectorQuestionScope.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Scope 2 ProductWise" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <TotalProductWise
                                            key={key}
                                            category={"Product-Wise Emission Scope2"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="PRODUCTWISEPIECHART"                                         
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                          {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                        totalScope1AndScope2?.length > 0 &&
                        totalScope1AndScope2.map((item, index) =>
                        !Object.keys(item).length
                        ? null
                        : Object.keys(item).map((key) => {
                            if (
                              key === "Scope 1 and Scope 2 Comparison" &&                              item[key] &&
                              item[key].hasOwnProperty("series") &&
                              item[key].hasOwnProperty("categories")
                            ) {
                              return (
                                <Col
                                  key={index}
                                  xl={6}
                                  lg={6}
                                  md={12}
                                  className="mb-3"
                                >
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                  <QuestionTypeComponent
                                        key={key}
                                        category={"Scope 1 And Scope 2 Comparison"}
                                        data={item[key]}
                                        handleApplyFilters={handleApplyFilters}
                                        type="SCOPE"
                                        enable={true}
                                        total={true}
                                      />
                                  </div>
                                </Col>
                              );
                            } else {
                              return null;
                            }
                          })                          
                          )}
                          {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareLineQuestionScopeList?.length > 0 &&
                          compareLineQuestionScopeList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Compare Overall Scope 1" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={"Scope 1 Comparison Across Year"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARESCOPELINE"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                          
                          {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                        scope2AcrossYear?.length > 0 &&
                        scope2AcrossYear.map((item, index) =>
                        !Object.keys(item).length
                        ? null
                        : Object.keys(item).map((key) => {
                            if (
                              key === "Scope 2 Comparison Across Year" &&                              item[key] &&
                              item[key].hasOwnProperty("series") &&
                              item[key].hasOwnProperty("categories")
                            ) {
                              return (
                                <Col
                                  key={index}
                                  xl={6}
                                  lg={6}
                                  md={12}
                                  className="mb-3"
                                >
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                  <QuestionTypeComponent
                                        key={key}
                                        category={key}
                                        data={item[key]}
                                        handleApplyFilters={handleApplyFilters}
                                        type="SCOPE"
                                        enable={true}
                                        total={true}
                                      />
                                  </div>
                                </Col>
                              );
                            } else {
                              return null;
                            }
                          })                          
                          )}
                          
                            {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          locationBasedEmissionAcrossYear?.length > 0 &&
                          locationBasedEmissionAcrossYear.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Location Based Emission Across Year" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={"Location Based Emission Across Year"}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARESCOPELINE"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {/* {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          questionCatqoryList?.length > 0 &&
                          questionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "GHG Emissions" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <QuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="CATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareQuestionCatqoryList?.length > 0 &&
                          compareQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Compare GHG Emissions" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreQuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARECATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareLineQuestionCatqoryList?.length > 0 &&
                          compareLineQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Compare Overall GHG Emissions" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARELINE"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          questionScopesList?.length > 0 &&
                          questionScopesList.map((item, index) =>
                            !Object.keys(item).length ? null : (
                              <Col
                                key={index}
                                xl={6}
                                lg={6}
                                md={12}
                                className="mb-3"
                              >
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  {Object.keys(item).map((key) =>
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories") ? (
                                      <QuestionTypeComponent
                                        key={key}
                                        category={key}
                                        data={item[key]}
                                        handleApplyFilters={handleApplyFilters}
                                        type="SCOPE"
                                        enable={true}
                                        total={true}
                                      />
                                    ) : null
                                  )}
                                </div>
                              </Col>
                            )
                          )}

                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareQuestionScopesList?.length > 0 &&
                          compareQuestionScopesList.map((item, index) =>
                            !Object.keys(item).length ? null : (
                              <Col
                                key={index}
                                xl={6}
                                lg={6}
                                md={12}
                                className="mb-3"
                              >
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  {Object.keys(item).map((key) =>
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories") ? (
                                      <ComapreQuestionTypeComponent
                                        key={key}
                                        category={key}
                                        data={item[key]}
                                        handleApplyFilters={handleApplyFilters}
                                        type="COMPARESCOPE"
                                        enable={false}
                                        total={true}
                                      />
                                    ) : null
                                  )}
                                </div>
                              </Col>
                            )
                          )} */}
                      
                      </Row>
                    )}
                  </Tab>
                )} 
                 {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Water" title="Water">
                    {tab === "Water" && (
                      <Row>
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          questionCatqoryList?.length > 0 &&
                          questionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Water Consupmtion" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <QuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="CATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareQuestionCatqoryList?.length > 0 &&
                          compareQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key === "Compare Water Consupmtion" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreQuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARECATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareLineQuestionCatqoryList?.length > 0 &&
                          compareLineQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    key ===
                                      "Compare Overall Water Consupmtion" &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARELINE"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                      </Row>
                    )}
                  </Tab>
                )} 
                 {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Diversity" title="Diversity">
                    {tab === "Diversity" && (
                      <Row>
                    
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          questionCatqoryList?.length > 0 &&
                          questionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    (key === "Employee Age-Based Diversity" ||
                                      key === "Worker Age-Based Diversity" ||
                                      key === "Total Employee") &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <QuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="CATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareQuestionCatqoryList?.length > 0 &&
                          compareQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    (key ===
                                      "Compare Employee Age-Based Diversity" ||
                                      key ===
                                        "Compare Worker Age-Based Diversity" ||
                                      key === "Compare Total Employee") &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreQuestionTypeComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARECATEGORIES"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          compareLineQuestionCatqoryList?.length > 0 &&
                          compareLineQuestionCatqoryList.map((item, index) =>
                            !Object.keys(item).length
                              ? null
                              : Object.keys(item).map((key) => {
                                  if (
                                    (key ===
                                      "Compare Overall Employee Age-Based Diversity" ||
                                      key ===
                                        "Compare Overall Worker Age-Based Diversity" ||
                                      key ===
                                        "Compare Overall Total Employee") &&
                                    item[key] &&
                                    item[key].hasOwnProperty("series") &&
                                    item[key].hasOwnProperty("categories")
                                  ) {
                                    return (
                                      <Col
                                        key={index}
                                        xl={6}
                                        lg={6}
                                        md={12}
                                        className="mb-3"
                                      >
                                        <div className="bg-white widget-style1 p-0 summery__widget">
                                          <ComapreLineChartComponent
                                            key={key}
                                            category={key}
                                            data={item[key]}
                                            handleApplyFilters={
                                              handleApplyFilters
                                            }
                                            type="COMPARELINE"
                                            enable={false}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                          )}
                      </Row>
                    )}
                  </Tab>
                )} 
                {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Waste" title="Waste">
                    {tab === "Waste" && (
                      <Row>
                        <>
                          {permissionList.some(
                            (permission) =>
                              permission.permissionCode ===
                                "QUESTION_TYPE_PROGRESS" && permission.checked
                          ) &&
                            wasteGraph && (
                              <>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Waste Management"}
                                      category={"Waste Management"}
                                      data={{
                                        ...wasteGraph["Waste Management"],
                                        series: [
                                          wasteGraph["Waste Management"].series[
                                            wasteGraph["Waste Management"]
                                              .series.length - 1
                                          ],
                                        ],
                                      }}
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Waste Management"}
                                      category={"Compare Waste Management"}
                                      data={wasteGraph["Waste Management"]}
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                          {permissionList.some(
                            (permission) =>
                              permission.permissionCode ===
                                "QUESTION_TYPE_PROGRESS" && permission.checked
                          ) &&
                            recoveredWasteGraph && (
                              <>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Waste Recovered"}
                                      category={"Waste Recovered"}
                                      data={{
                                        ...recoveredWasteGraph[
                                          "Waste Recovered"
                                        ],
                                        series: [
                                          recoveredWasteGraph["Waste Recovered"]
                                            .series[
                                            recoveredWasteGraph[
                                              "Waste Recovered"
                                            ].series.length - 1
                                          ],
                                        ],
                                      }}
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Waste Recovered"}
                                      category={"Compare Waste Recovered"}
                                      data={
                                        recoveredWasteGraph["Waste Recovered"]
                                      }
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                          {permissionList.some(
                            (permission) =>
                              permission.permissionCode ===
                                "QUESTION_TYPE_PROGRESS" && permission.checked
                          ) &&
                            disposedWasteGraph && (
                              <>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Waste disposed"}
                                      category={"Waste disposed"}
                                      data={{
                                        ...disposedWasteGraph["Waste disposed"],
                                        series: [
                                          disposedWasteGraph["Waste disposed"]
                                            .series[
                                            disposedWasteGraph["Waste disposed"]
                                              .series.length - 1
                                          ],
                                        ],
                                      }}
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Waste disposed"}
                                      category={"Compare Waste disposed"}
                                      data={
                                        disposedWasteGraph["Waste disposed"]
                                      }
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                          {permissionList.some(
                            (permission) =>
                              permission.permissionCode ===
                                "QUESTION_TYPE_PROGRESS" && permission.checked
                          ) &&
                            totalWasteGraph && (
                              <>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Total Waste"}
                                      category={"Total Waste"}
                                      data={{
                                        ...totalWasteGraph["Total Waste"],
                                        series: [
                                          totalWasteGraph["Total Waste"].series[
                                            totalWasteGraph["Total Waste"]
                                              .series.length - 1
                                          ],
                                        ],
                                      }}
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                                <Col xl={6} lg={6} md={12} className="mb-3">
                                  <div className="bg-white widget-style1 p-0 summery__widget">
                                    <ComapreQuestionTypeComponent
                                      key={"Total Waste"}
                                      category={"Compare Total Waste"}
                                      data={totalWasteGraph["Total Waste"]}
                                      handleApplyFilters={handleApplyFilters}
                                      type="COMPAREWASTE"
                                      enable={false}
                                      total={true}
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                        </>
                      </Row>
                    )}
                  </Tab>
                )}
                {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Safety" title="Safety">
                    {tab === "Safety" && (
                      <>
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          sefety && (
                            <Col xl={6} lg={6} md={12} className="mb-3">
                              <div className="bg-white widget-style1 p-0 summery__widget">
                                <ComapreQuestionTypeComponent
                                  key={"Safety"}
                                  category={"Safety"}
                                  data={sefety["Safety"]}
                                  handleApplyFilters={handleApplyFilters}
                                  type="COMPAREWASTE"
                                  enable={false}
                                  total={true}
                                />
                              </div>
                            </Col>
                          )}
                      </>
                    )}
                  </Tab>
                )}
                {permissionList.some(
                  (permission) =>
                    permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
                    permission.checked
                ) && (
                  <Tab eventKey="Training" title="Training">
                    {tab === "Training" && (
                      <Row>
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          employeeTraining && (
                            <>
                              <Col xl={6} lg={6} md={12} className="mb-3">
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  <ComapreQuestionTypeComponent
                                    key={"Training"}
                                    category={"Employee Training"}
                                    data={{
                                      ...employeeTraining["Employee Training"],
                                      series: [
                                        employeeTraining["Employee Training"]
                                          .series[
                                          employeeTraining["Employee Training"]
                                            .series.length - 1
                                        ],
                                      ],
                                    }}
                                    handleApplyFilters={handleApplyFilters}
                                    type="COMPARETRAINING"
                                    enable={false}
                                    total={true}
                                  />
                                </div>
                              </Col>
                              <Col xl={6} lg={6} md={12} className="mb-3">
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  <ComapreQuestionTypeComponent
                                    key={"Training"}
                                    category={"Compare Employee Training"}
                                    data={employeeTraining["Employee Training"]}
                                    handleApplyFilters={handleApplyFilters}
                                    type="COMPARETRAINING"
                                    enable={false}
                                    total={true}
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          workerTraining && (
                            <>
                              <Col xl={6} lg={6} md={12} className="mb-3">
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  <ComapreQuestionTypeComponent
                                    key={"Training"}
                                    category={"Worker Training"}
                                    data={{
                                      ...workerTraining["Worker Training"],
                                      series: [
                                        workerTraining["Worker Training"]
                                          .series[
                                          workerTraining["Worker Training"]
                                            .series.length - 1
                                        ],
                                      ],
                                    }}
                                    handleApplyFilters={handleApplyFilters}
                                    type="COMPARETRAINING"
                                    enable={false}
                                    total={true}
                                  />
                                </div>
                              </Col>
                              <Col xl={6} lg={6} md={12} className="mb-3">
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  <ComapreQuestionTypeComponent
                                    key={"Worker Training"}
                                    category={"Compare Worker Training"}
                                    data={workerTraining["Worker Training"]}
                                    handleApplyFilters={handleApplyFilters}
                                    type="COMPARETRAINING"
                                    enable={false}
                                    total={true}
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                        {permissionList.some(
                          (permission) =>
                            permission.permissionCode ===
                              "QUESTION_TYPE_PROGRESS" && permission.checked
                        ) &&
                          training && (
                            <>
                              <Col xl={6} lg={6} md={12} className="mb-3">
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  <ComapreQuestionTypeComponent
                                    key={"Training"}
                                    category={"Total Training"}
                                    data={{
                                      ...training["Training"],
                                      series: [
                                        training["Training"].series[
                                          training["Training"].series.length - 1
                                        ],
                                      ],
                                    }}
                                    handleApplyFilters={handleApplyFilters}
                                    type="COMPARETRAINING"
                                    enable={false}
                                    total={true}
                                  />
                                </div>
                              </Col>
                              <Col xl={6} lg={6} md={12} className="mb-3">
                                <div className="bg-white widget-style1 p-0 summery__widget">
                                  <ComapreQuestionTypeComponent
                                    key={"Training"}
                                    category={"Compare Total Training"}
                                    data={training["Training"]}
                                    handleApplyFilters={handleApplyFilters}
                                    type="COMPARETRAINING"
                                    enable={false}
                                    total={true}
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                      </Row>
                    )}
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>

          <Row>

          </Row>
        </div>
      </div>
      <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <label className="align-items-center"> ESG REPORT </label>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Financial Year</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--select--</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Framework</Form.Label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck() {}}
                  options={[
                    "Designmation 1",
                    "Designmation 1",
                    "Designmation 2",
                    "Designmation 3",
                    "Designmation 4",
                  ]}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Report Type</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--select--</option>
                  <option value="1">Custom</option>
                  <option value="2">Standard</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select File Type</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--select--</option>
                  <option value="1">Excel</option>
                  <option value="2">CSV</option>
                  <option value="3">PDF</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="new_button_style"
            onClick={() => {
              setShow(false);
            }}
          >
            Download
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
