import React, { useState, useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';

const ColumnChartForSource = (props) => {
  const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
          
        series: [{
          name: 'PRODUCT A',
          data: [44, 55, 41, 67, 22, 43, 21, 49]
        }, {
          name: 'PRODUCT B',
          data: [13, 23, 20, 8, 13, 27, 33, 12]
        }, {
          name: 'PRODUCT C',
          data: [11, 17, 15, 15, 21, 14, 15, 13]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%'
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          xaxis: {
            categories: ['2011 Q1', '2011 Q2', '2011 Q3', '2011 Q4', '2012 Q1', '2012 Q2',
              '2012 Q3', '2012 Q4'
            ],
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 50
          },
        },    
      
      }
    

  
);
useEffect(() => {
  if (props?.chartData) {
    setChartData(props?.chartData);
    if (props?.chartData?.series) {
      const newChartData = { ...props.chartData };
      const numColumns = props?.chartData.series.length;
      const columnWidth = parseInt(
        props?.chartData.options.plotOptions.bar.columnWidth
      );
      const categories = props?.chartData.options.xaxis.categories.length;
   

        newChartData.options.plotOptions.bar.columnWidth = "30px";
        
        setChartData(newChartData);
      

      if (chartRef.current) {
        chartRef.current.style.width =
          numColumns * columnWidth * categories > 820
            ? `${numColumns * columnWidth * categories}px`
            : "820px";
      }
    }
  }
}, [props]);

return (
  <div style={{ overflowX: "auto", width: "100%", minWidth: "100%" }}>
    <div ref={chartRef}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={props?.height||435}
      />
    </div>
  </div>
);
};

export default ColumnChartForSource;
