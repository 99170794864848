// import React, { useEffect, useRef, useState } from "react";
// import { Col, Form, Modal } from "react-bootstrap";
// import { ColumnChart } from "./Chart";
// import { ColumnChartDataForQuestionType } from "./chartData";
// import config from "../../../../config/config.json";
// import { apiCall } from "../../../../_services/apiCall";
// import FilterDashoard from "../../../../img/sector/filter.png";
// import Multiselect from "multiselect-react-dropdown";

// const MyDisclosureProgressComponent = () => {
//   const [filter, setFilter] = useState(false);
//   const [graphData, setGraphData] = useState([]);
//   const [selectedFramework, setSelectedFramework] = useState([]);
//   const [selectedFrameworkId, setSelectedFrameworkId] = useState([]);
//   const [Framework, setFramework] = useState([]);
//   const [selectedChartType, setSelectedChartType] = useState();
//   const [chartType, setChartType] = useState("");
//   const [allZero, setAllZero] = useState(false);
//   const multiselectRef = useRef(null);
//   const handleFilterClose = () => setFilter(false);

//   const myDisclosureProgress = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}myDisclosureProgress`,
//       {},
//       {},
//       "GET"
//     );
//     if (isSuccess) {
//       const responseData = data.data;
//       const allZeroes = responseData?.series.every((series) =>
//         series.data.every((value) => value === 0)
//       );
//       setAllZero(allZeroes);
//       const GraphData = await ColumnChartDataForQuestionType(
//         responseData?.series,
//         responseData?.categories,
//         false,
//         true
//       );
//       setGraphData(GraphData);
//       setFramework(responseData?.frameworkOptions);
//       setSelectedFrameworkId(responseData?.filter?.frameworkIds);
//       const filteredArray = responseData?.frameworkOptions.filter((obj) =>
//         responseData?.filter?.frameworkIds.includes(obj.id)
//       );
//       setSelectedFramework(filteredArray);
//     }
//   };
//   const onSelectHandler = (data) => {
//     const selectedIds = data && data.map(({ id }) => id);
//     setSelectedFrameworkId(selectedIds || []);
//     setSelectedFramework(data || []);
//   };

//   const onRemoveHandler = (data, type) => {
//     if (data && data.length === 0) {
//       setSelectedFrameworkId([]);
//       setSelectedFramework([]);
//     } else {
//       onSelectHandler(data);
//     }
//   };
//   const handleApplyFilter = async () => {};

//   useEffect(() => {
//     myDisclosureProgress();
//   }, []);
//   return (
//     <>
//       <Col md={12}>
//         <div style={{ padding: 25 }}>
//           <div className="esg_score_title d-flex align-items-center justify-content-between">
//             <h5>
//               <b>My Disclosure Progress</b>
//             </h5>
//             {!allZero && (
//               <button
//                 onClick={() => {
//                   setFilter(true);
//                 }}
//                 className="new_button_style"
//               >
//                 <i
//                   className="fas fa-filter"
//                   title="My Disclosure Progress Filter"
//                 ></i>
//               </button>
//             )}
//           </div>
//           <div className="p-0">
//             <div className="main_text">
//               {chartType !== "" ? (
//                 chartType
//               ) : (
//                 <ColumnChart chartData={graphData} />
//               )}
//             </div>
//           </div>
//         </div>
//       </Col>
//       <Modal size="md" show={filter} onHide={handleFilterClose}>
//         <Modal.Header closeButton>
//           <Form.Label className="align-items-center m-0">
//             <strong>My Disclosure Progress Filter</strong>
//           </Form.Label>
//         </Modal.Header>
//         <Modal.Body>
//           <Form.Group className="mb-3" controlId="formStatusType">
//             <Form.Label>Select Framework Name</Form.Label>
//             <Multiselect
//               placeholder="Select Framework"
//               displayValue="title"
//               className="multi_select_dropdown w-100"
//               options={Framework}
//               selectedValues={selectedFramework}
//               ref={multiselectRef}
//               onRemove={(removedItem) => {
//                 onRemoveHandler(removedItem);
//               }}
//               onSelect={(selectedItems) => {
//                 onSelectHandler(selectedItems);
//               }}
//             />
//           </Form.Group>
//           <Form.Group className="mb-3" controlId="formChartType">
//             <Form.Label>Select Chart Type</Form.Label>
//             <Form.Select
//               aria-label="Select Chart Type"
//               onChange={(e) => setSelectedChartType(e.target.value)}
//               value={selectedChartType}
//             >
//               <option value="ColumnChart">Column Chart</option>
//               <option value="BarChart">Bar Chart</option>
//             </Form.Select>
//           </Form.Group>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="new_button_style" onClick={handleApplyFilter}>
//             Apply
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default MyDisclosureProgressComponent;
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import { useHistory } from 'react-router-dom';
import { ColumnChartData } from "./chartData";
import ColumnChartForSource from './Chart/ColumnChartForSource';

const SummeryGraph = () => {
  const history = useHistory();
  const [graphData, setGraphData] = useState({});
  const [chartData, setChartData] = useState(null);
  
  const [hoveredData, setHoveredData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [centerPercentage, setCenterPercentage] = useState(null);

  const overAllStatusOverview = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}overAllStatusOverview`,
      {},
      {},
      'GET'
    );

    if (isSuccess) {
   
      const GraphData = await ColumnChartData(['Answered', 'Accepted', 'Rejected', 'Not Responded'], [{
        data: [0, 100, 0, 0,]
      }]);
      setChartData(GraphData);
      setGraphData(data?.data);
      setSelectedCategory(Object.keys(data?.data)[2]);
    }
  };

  useEffect(() => {
    overAllStatusOverview();
  }, []);

  const getCategoryData = (category) => {
    if (!graphData[category]) {
      return [];
    }

    return [{
      name: 'Answered',
      data: [graphData[category]?.answered ? graphData[category]?.answered : 0]
    }, {
      name: 'Accepted',
      data: [graphData[category]?.accepted ? graphData[category]?.accepted : 0]
    }, {
      name: 'Rejected',
      data: [graphData[category]?.rejected ? graphData[category]?.rejected : 0]
    }, {
      name: 'Not Responded',
      data: [graphData[category]?.notResponded ? graphData[category]?.notResponded : 0]
    }

    ];
    // return [
    //   graphData[category]?.answered ? graphData[category]?.answered : 0,
    //   graphData[category]?.accepted ? graphData[category]?.accepted : 0,
    //   graphData[category]?.rejected ? graphData[category]?.rejected : 0,
    //   graphData[category]?.notResponded ? graphData[category]?.notResponded : 0,
    // ];
  };

  // const getOptions = (category) => {
  //   const seriesData = getCategoryData(category);
  //   const allZeroes = false;

  //   const options = {
  //     chart: {
  //       width: 680,
  //       type: 'bar',
  //     },
  //     labels: ['Answered', 'Accepted', 'Rejected', 'Not Responded'],

  //     ...(allZeroes ? { colors: ['#e9ecef'] } : {}),

  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 250,
  //           },
  //         },
  //       },
  //     ],
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         dataLabels: {
  //           enabled: false,
  //         },
  //       },
  //     },
  //     dataLabels: {
  //       enabled: !allZeroes,
  //       formatter: function () {
  //         return centerPercentage !== null ? `${parseFloat(centerPercentage).toFixed(2).replace(/\.?0+$/, '')}%` : '';
  //       },
  //       offsetY: -10,
  //       style: {
  //         fontSize: '18px',
  //       },
  //     },
  //     tooltip: {
  //       enabled: !allZeroes,
  //       y: {
  //         formatter: function (value) {
  //           setCenterPercentage(value);
  //           return `${parseFloat(value).toFixed(2).replace(/\.?0+$/, '')}%`;
  //         },
  //       },
  //     },

  //   };

  //   if (!allZeroes) {
  //     options.legend = {
  //       display: true,
  //       position: 'bottom',
  //       horizontalAlign: 'center',
  //       offsetY: 0,
  //       style: {
  //         right: '5px',
  //         top: '77px', // Adjust this value as needed
  //       },
  //     };
  //   }

  //   return {
  //     series: allZeroes ? [1] : seriesData,
  //     options: options,
  //   };
  // };

  const getOptions = (category) => {
    const seriesData = getCategoryData(category);
    const allZeroes = false;

    const options = {
      chart: {
        width: 680,
        type: 'bar',
      },
      labels: ['Answered', 'Accepted', 'Rejected', 'Not Responded'],

      ...(allZeroes ? { colors: ['#e9ecef'] } : {}),

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            enabled: false,
          },
        },
      },
      dataLabels: {
        enabled: !allZeroes,
        formatter: function () {
          return centerPercentage !== null ? `${parseFloat(centerPercentage).toFixed(2).replace(/\.?0+$/, '')}%` : '';
        },
        offsetY: -10,
        style: {
          fontSize: '18px',
        },
      },
      tooltip: {
        enabled: !allZeroes,
        y: {
          formatter: function (value) {
            setCenterPercentage(value);
            return `${parseFloat(value).toFixed(2).replace(/\.?0+$/, '')}%`;
          },
        },
      },

    };

    if (!allZeroes) {
      options.legend = {
        display: true,
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 0,
        style: {
          right: '5px',
          top: '77px', // Adjust this value as needed
        },
      };
    }

    return {
      series: allZeroes ? [1] : seriesData,
      options: options,
    };
  };


  const handleCheckboxChange = (category) => {
    setSelectedCategory(category);
    setCenterPercentage(null);
  };

  useEffect(() => {
    console.log(hoveredData, "Hovered Data");
  }, [hoveredData]);

  return (
    <>
      <div className="canvas-con-inner">
        {Object.keys(graphData).map((category, index) => {
          if (category === 'Trends' || category === 'Tabular') {
            return (
              <div key={index}>
                <input
                  type="radio"
                  id={`checkbox-${category}`}
                  checked={selectedCategory === category}
                  onChange={() => handleCheckboxChange(category)}
                />
                <label className="fs-5" htmlFor={`checkbox-${category}`}>{category}</label>
                <div className='graph_data'>
                {chartData && selectedCategory === category &&
                  <ColumnChartForSource chartData={chartData}  height={400} />
                }
              </div>
            
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      {/* <h6>{centerPercentage !== null ? `${parseFloat(centerPercentage).toFixed(2).replace(/\.?0+$/, '')}%` : ''}</h6> */}

    </>
  );
};

export default SummeryGraph;
