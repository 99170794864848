export const ColumnChartDataForQuestionType = async (
  series,
  categories,
  stacked,
  enabled,
  unit
) => {

  const onSelect = (data) => {
    const questionIds = [data?.questionId];
    localStorage.setItem('questionIds', questionIds);
    window.location.href = '/#/sector_questions';

  };
  const allZeroes = series.every(series => series.data.every(value => value === 0));

  let chartSeries;
  if (allZeroes) {
    chartSeries = series.map(({ name }) => ({
      name: name,
      data: [1]
    }));
  } else {
    chartSeries = series;
  }
  const chartData = {
    series: chartSeries,
    options: {
      ...(allZeroes ? { colors: ['#e9ecef'] } : {}),
      chart: {
        toolbar: {
          show: false,
          download: false // Remove download button
        },
        type: allZeroes ? "line" : "bar",
        height: 350,
        stacked: allZeroes ? false : stacked,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const seriesIndex = config.seriesIndex;
            onSelect(series[seriesIndex]);
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      dataLabels: {
        enabled: false,    
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '54.%',
         
          // dataLabels: {
          //   position: 'top', // top, center, bottom
          // },
        },
      },
      xaxis: {
        categories: categories,
      },
      fill: {
        opacity: 1,
      },

      yaxis: {
        title: {
          text: unit ? unit : "Pecentage",
        },
      },
      fill: {
        opacity: 1,
      },
 
      tooltip: {
        
        y: { 
          formatter: function (val) {
            return `${val} ${unit ? unit : "Percentage"}`;
          },
        },
        enabled: allZeroes ? false: true, 
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
    },
  };
  return chartData;
};


export const ColumnChartDataForQuestionTypess = async (
  series,
  categories,
  stacked,
  enabled,
  unit
) => {

  const onSelect = (data) => {
    const questionIds = [data?.questionId];
    localStorage.setItem('questionIds', questionIds);
    window.location.href = '/#/sector_questions';

  };
  const allZeroes = series.every(series => series.data.every(value => value === 0));

  let chartSeries;
  if (allZeroes) {
    chartSeries = series.map(({ name }) => ({
      name: name,
      data: [1]
    }));
  } else {
    chartSeries = series;
  }
  const chartData = {
    series: chartSeries,
    options: {
      ...(allZeroes ? { colors: ['#e9ecef'] } : {}),
      chart: {
        toolbar: {
          show: false,
          download: false // Remove download button
        },
        type: allZeroes ? "line" : "bar",
        height: 350,
        stacked: allZeroes ? false : stacked,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const seriesIndex = config.seriesIndex;
            onSelect(series[seriesIndex]);
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      dataLabels: {
        enabled: allZeroes ? false: enabled,    
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '46%',
         
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: categories,
      },
      fill: {
        opacity: 1,
      },

      yaxis: {
        title: {
          text: unit ? unit : "Pecentage",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: { 
          formatter: function (val) {
            return val + " " + unit;
          },
        },
        x: { show: false },
        enabled: allZeroes ? false: true, 
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
    },
  };
  return chartData;
};
export const RadioBarChartData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        height: 1000,
        type: "radialBar",
        toolbar: {
          show: false,
          download: false // Remove download button
        },
        offsetY: -10, 
        margin: {
          bottom: 10 
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '20%', 
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5
            }
          },
          track: {
            strokeWidth: '90%',
          },
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
          total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return (
                  (w.globals.series.reduce((a, b) => a + b, 0) / 1).toFixed(2) +
                  "%"
                );
              },
            },
         
          },
        },
      },
      labels: level,
      legend: {
        show: true,
        position: "bottom",
        offsetY: -27, 
      },
    },
  };
  return chartData;
};

export const ColumnChartData = async (level, series) => {
  const chartData = {
          
    series: [{
      data: [0, 100, 0, 0]
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
          download: false // Remove download button
        },
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      colors: ['rgb(254, 176, 25)',  'rgb(0, 227, 150)', 'rgb(255, 69, 96)', '#008FFB'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
         
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [
          ['Answered'],
          ['Accepted'],
          ['Rejected'],          
          ['Not Responded'],         
        ],
        labels: {
          style: {
            colors: ['rgb(254, 176, 25)',  'rgb(0, 227, 150)', 'rgb(255, 69, 96)', '#008FFB'],
            fontSize: '12px'
          }
        }
      }
    },
  }
  return chartData;
};
export const ColumnChartDataForSource = async (teamWorkloadResults, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
          download: false // Remove download button
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      dataLabels: {
        enabled: false, // Disable data labels
      },
      plotOptions: {
        bar: {
          horizontal: false,
         
          // dataLabels: {
          //   enabled: false,
          // },
         
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: teamWorkloadResults.map(
          (user) => `${user?.firstName} ${user?.lastName}`
        ),
      },
      yaxis: {
        labels: {
          formatter: (value) => `${value}%`, // Display percentages on the y-axis
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        offsetY: 40,
      },
      tooltip: {
        y: {
          formatter: (value) => `${value}%`, // Display percentages in the tooltip
        },
      },
    },
  };

  return chartData;
};
export const BarChartDataForQuestionType = async (series, categories, unit) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 430,
        stacked: true,
        toolbar: {
          show: false,
          download: false // Remove download button
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: categories,
        title: {
          text: unit ? unit : "Pecentage",
        },
      },
      // yaxis: {
      //   title: {
      //     text: unit,
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " " + unit;
          },
        },
        x: { show: false },
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
    },
  };
  return chartData;
};
export const BarChartDataForSource = async (
  teamWorkloadResults,
  filteredSeries
) => {
  const chartData = {
    series: filteredSeries,
    options: {
      chart: {
        type: "bar",
        height: 500,
        stacked: true,
        toolbar: {
          show: false,
          download: false // Remove download button
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },

      xaxis: {
        categories: teamWorkloadResults.map(
          (user) => `${user?.firstName} ${user?.lastName}`
        ),
      },
      yaxis: {
        labels: {
          formatter: (value) => `${value}`,
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };

  return chartData;
};
export const BarChartData = async (level, series) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 500,
        toolbar: {
          show: false,
          download: false // Remove download button
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth:"45%",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      // title: {
      //   text: "Bar View",
      // },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: level,
        title: {
          text: "% (Percentage)",
        },
      },
    },
  };
  return chartData;
};
export const PieChartData = async (series, level, type,horizonatal) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        width: 380,
        type: type,
        toolbar: {
          show: false,
          download: false // Remove download button
        },
      },
     
      labels: level,
      legend: {
        show: false
      },
      stroke: {
        curve: 'straight'
      },
      plotOptions: {
        bar: {
          horizontal: horizonatal,
        columnWidth: '26%',
        barWidth: '26%',
        endingShape: 'rounded'
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          show: false,
          position: 'bottom'
        }
      }
    }]
  },

};
return chartData;
};
export const ProductsPieChartData = async (series, level, type, horizonatal) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        width: 380,
        type: 'pie',
        toolbar: {
          show: false,
          download: false // Remove download button
        },
      },
      labels: level,
      legend: {
        position: "bottom",
        horizontalAlign: "left",
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: true,
            position: "",
            horizontalAlign: "left",
          }
        }
      }]
    }
  };
  return chartData;
};
export const ProductsScaterChartData = async (series,level) => {
  // const seriesWithGap = series.map(serie => [{name: '', data: []}, ...serie]);

  const chartData = {
    series: series,
    options: {
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy'
        },
        toolbar: {
          show: false,
          download: false // Remove download button
        },
      },
      xaxis: {
        categories: [''].concat(level),
      },
      // yaxis: {
      //   min: 200, 
      //   // tickAmount: 3, 
      //   labels: {
      //     formatter: function (value) {
      //       return value; // Keep labels as they are
      //     }
      //   }
      // },
      yaxis: {
        tickAmount: 3
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
    },
  };
  return chartData;
};
export const LineChartData = async (series, level, unit) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
          download: false // Remove download button
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 5,
        dashArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] 
      },
      title: {
        title: {
          text: '', 
          align: 'left',
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30px' // Adjust the column width as needed
        }
      }, 
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          }
        ]
      },
      animations: {
        enabled: true, // Enable animations
        easing: 'linear', // Animation easing type
        dynamicAnimation: {
          speed: 1000 // Speed of dynamic animations
        }
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: level,
      },
    },
  };

  return chartData;
};









